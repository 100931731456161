import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { faGavel, faMobileScreenButton, faStar, faPersonDotsFromLine, faClipboardList, faNewspaper, faPeopleGroup, faPersonChalkboard, faMedal } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BootstrapTable from "react-bootstrap-table-next";
import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import filterFactory, {
  textFilter,
  customFilter,
  selectFilter,
  Comparator,
  FILTER_TYPES,
} from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
//import LoadingBar from "react-top-loading-bar";
import { Chart } from "react-google-charts";
import { Bar, Line } from "react-chartjs-2";
import ApexChart from "./ApexChart";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Multiselect } from "react-widgets";
import ClientHeader from "./ClientHeader";

import Quoteinfoform from "../PClient/Quoteform";

//import 'flag-icon-css/css/flag-icon.min.css';
//import '../styles/summary.css';
// showing progress bar npm
import Swal from "sweetalert2";
import { PropagateLoader } from "react-spinners";
import LoadingBar from "react-top-loading-bar";
import { NumberFormatter } from "react-number-formatter";
import { object } from "yup";
import './Client.scss';
var jwt=require('jsonwebtoken');
var jwtkey= require('../config');
export const Clientpagerefresh = () => {
  //alert('Hi Prakash')
  window.location.reload(true);
};

class ClientKolProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kol_id: "",
      client_name: "",
      secondryEmail: "",
      client_token:"",
      redirectknowmore: false,
      // Star Data
      dol: false,
      sanction: false,
      star: false,
      focusAreas: [],
      focusConditions: [],
      focusProcedures: [],
      // state for progress bar
      ShowBar: false,
      progressBar: false,
      infoQuoteModal: false,
      DoctorCompleteName: "",
      ranking: "",
      procedureshowtype: false,

      multiplekolquotmodel: false,
      lock_unlock_flag: false,

      DoctorFormattedContactNumber: "",
      DoctorFormattedFaxNumber: "",
      DoctorFormattedAssistentContactNumber: "",
      CountryListData: [],
      ProcedureData: [],
      SpecialityData: [],

      QuoteData: {
        client_id: "",
        client_name: "",
        kol_id: "",
        no_of_deep_profile: 0,
        no_of_basic_profile: 0,
        no_of_kol: "",
        specialty_id: "",
        procedure_type: "",
        countrieslist: [],
        Multiple_single_profile: "",

        status: "New",
      },
      premiumInsightColumns: [
        {
          dataField: 'maping_area',
          text: 'Top Ranked',
          formatter: (cell, row) => {
            // <i class="fa-solid fa-medal" style="color: #c0c0c0;"></i>
            // <i class="fa-solid fa-medal" style="color: #cd7f32;"></i>
            return (
              <div style={{ fontWeight: "bold", verticalAlign: "baseline", textAlign: "center" }}>
                {row.rank} &nbsp;&nbsp;
                {row.rank === 1 ? <FontAwesomeIcon icon={faMedal} style={{ color: "#ffd700" }} size="2xl" /> : <div style={{ display: "none" }} />}
                {row.rank === 2 ? <FontAwesomeIcon icon={faMedal} style={{ color: "#c0c0c0" }} size="2xl" /> : <div style={{ display: "none" }} />}
                {row.rank === 3 ? <FontAwesomeIcon icon={faMedal} style={{ color: "#cd7f32" }} size="2xl" /> : <div style={{ display: "none" }} />}
              </div>
            );
          },
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff', textAlign: "center", backgroundColor: "#EDE9E8" };
          },
          style: {
            border: "2px solid #EDE9E8"
          }
        },
        {
          dataField: 'maping_area',
          text: 'Activities/Engagements',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff', textAlign: "center", backgroundColor: "#EDE9E8" };
          },
          style: {
            border: "2px solid #EDE9E8"
          }
        },
        {
          dataField: 'procedure_type',
          text: 'Clinical Procedure(s)',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff', textAlign: "center", backgroundColor: "#EDE9E8" };
          },
          style: {
            border: "2px solid #EDE9E8"
          }
        },
        {
          dataField: 'maping_condition',
          text: 'Disease Condition(s)',
          headerStyle: (colum, colIndex) => {
            return { borderColor: '#02365F', borderTop: 'solid', borderTopColor: '#fff', borderLeft: 'solid', borderLeftColor: '#fff', textAlign: "center", backgroundColor: "#EDE9E8" };
          },
          style: {
            border: "2px solid #EDE9E8"
          }
        },
      ],
      //****************** */ Chart Color **************
      annotationfontsize: 10,
      annotationfontcolor: "Black",
      annotationfontbold: false,

      legendfontsize: 12,
      legendfontcolor: "Black",
      legendfontbold: false,

      titlefontsize: 18,
      titlefontcolor: "#035B96",
      titlefontbold: true,

      hAxisfontsize: 12,
      hAxisfontcolor: "Black",
      hAxisfontbold: false,

      vAxisfontsize: 14,
      vAxisfontcolor: "Black",
      vAxisfontbold: true,

      series1color: "#035B96",
      series2color: "#A55B52",
      series3color: "#72DADD",
      series4color: "#D7B4B0",

      chartAreawidth: "80%",
      chartAreaheight: "60%",

      // End Chart Color *****************

      // ProfileBySession: this.props.location.state.ProfileBySession,

      RedirectFlag: false,

      redirectBackToDashboard: false,
      redirectBackToKolList: false,
      redirectClientKolProfile: false,
      redirectClientViewBiography: false,
      //Flag to show form of selected drawer item
      PersonalInformationFlag: true,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      clientTrends: null,

      // to store data fetch from api
      PersonalInformation: {},
      WorkDetails: [],
      premiumInsightDetails: [],
      Biography: {},
      ProfessionalMemberships: [],
      Education: [],
      AdvisoryBoard: [],
      AwardsHonours: [],
      BooksMonographs: [],
      Commitees: [],
      ClinicalTrialDetails: [],
      EventsDetails: [],
      Grants: [],
      Guidelines: [],
      HospitalAffiliations: [],
      Interactions: [],
      PressItem: [],
      Presentation: [],
      PublicationDetails: [],
      SunshinePayment: [],

      profile_photo: "",
      LanguageSpoken: [],
      BiographyResume: "",
      profile_photo_for_edit: "",
      BiographyResume_edit: "",
      BiographyResumeFileName: "",
      FinalResumeName: "",

      CountryListData: [],
      StateData: [],
      CityData: [],
      EmployeeListData: [],

      // KolRatingScore data
      event_kol_rating: [],
      publication_kol_rating: [],
      ad_board_kol_rating: [],
      guideline_kol_rating: [],
      clinicaltrial_kol_rating: [],

      //piechart data
      event_piechart: [],
      ad_board_piechart: [],
      publication_piechart: [],
      guideline_piechart: [],
      clinicaltrial_piechart: [],

      home_piechart_flag: true,
      activityTab: false,
      showCompitiorTab: false,
      colabarationTab: false,

      // *************** New Chart Data **********************

      // all columns
      columnsWorkDetails: [
        {
          dataField: "current_place_of_work",
          text: "Current Place of Work",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            // return { textAlign: 'center', width: '10%' };
            return { width: "10%" };
          },
        },
        {
          dataField: "designation",
          text: "Designation",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        {
          dataField: "department",
          text: "Department",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        // new
        {
          dataField: "from_year",
          text: "From",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
        // new
        {
          dataField: "to_year",
          text: "To",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%" };
          },
        },
      ],

      columnsProfessionalMemberships: [
        {
          dataField: "organization",
          text: "Organization",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "12%" };
          },
        },
        {
          dataField: "organization_type",
          text: "Organization Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "12%" };
          },
        },
        {
          dataField: "department",
          text: "Department",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "12%" };
          },
        },
        {
          dataField: "position",
          text: "Position",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "12%" };
          },
        },

        {
          dataField: "location",
          text: "Location",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "12%" };
          },
        },

        {
          dataField: "tenure",
          text: "From",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "12%" };
          },
        },
        {
          dataField: "tenure1",
          text: "To",
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "12%" };
          },
        },

        {
          dataField: "organization_website",
          text: "Organization Website",

          formatter: (cell, row) => {
            return (
              <div>
                <a
                  href={row.organization_website}
                  target="_blank"
                  style={{
                    color: "#069",
                    wordWrap: "break-word",
                    fontSize: "12px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {row.organization_website}{" "}
                </a>
              </div>
            );
          },

          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "12%" };
          },
        },
      ],
      columnsEducation: [
        {
          dataField: "institute_name",
          text: "Alma  Mater",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "institute_type",
          text: "Alma Mater Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "degrees",
          text: "Qualification",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "speciality_type",
          text: "Speciality",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "years_attended_from",
          text: "From",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "years_attended_to",
          text: "To",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "source_link",
          text: "Institution Link",
          // align: 'center',
          formatter: (cell, row) => {
            return (
              <div>
                <a
                  href={row.source_link}
                  target="_blank"
                  style={{
                    color: "#069",
                    fontSize: "12px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {row.source_link}{" "}
                </a>
              </div>
            );
          },
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
      ],
      columnsAdvisoryBord: [
        {
          dataField: "year",
          text: "From",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        // new
        {
          dataField: "to_year",
          text: "To",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        // {
        //     dataField: 'speciality_type',
        //     text: 'Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'sub_specialty_id',
        //     text: 'Sub Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '15%' };
        //     },
        // },
        // {
        //     dataField: 'procedure_type_id',
        //     text: 'Procedure Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '15%' };
        //     },
        // },
        {
          dataField: "company",
          text: "Company",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        {
          dataField: "type_of_company",
          text: "Type of Company",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        {
          dataField: "advisory_board",
          text: "Advisory Board",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "topic",
          text: "Topic",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "role_id",
          text: "Role",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '15%' };
        //     },
        // },
      ],
      columnsAwardsHonours: [
        {
          dataField: "year",
          text: "From",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "5%" };
          },
        },
        // new
        {
          dataField: "to_year",
          text: "To",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "5%" };
          },
        },
        {
          dataField: "awards_honours",
          text: "Awards Honours",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "5%" };
          },
        },
        {
          dataField: "types",
          text: "Types of Awards & Honours",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "5%" };
          },
        },
      ],
      columnsBooksMonographs: [
        {
          dataField: "year",
          text: "Year",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        {
          dataField: "title",
          text: "Title",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "author",
          text: "Author",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "publication",
          text: "Publication",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "15%" };
          },
        },
        {
          dataField: "edition",
          text: "Edition",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "8%" };
          },
        },
        {
          dataField: "pages",
          text: "Pages",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "8%" };
          },
        },
        {
          dataField: "editors",
          text: "Editors",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "20%" };
          },
        },
      ],
      columnsCommitees: [
        {
          dataField: "year",
          text: "Year",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        // new
        {
          dataField: "body_org_name",
          text: "Body/Org Name",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        {
          dataField: "committee",
          text: "Committee",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        {
          dataField: "role_id",
          text: "Role",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
      ],
      columnsClinicalTrialDetails: [
        {
          dataField: "study_details",
          text: "Study Details",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        // {
        //     dataField: 'speciality_type',
        //     text: 'Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'sub_speciality',
        //     text: 'Sub Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'procedure_type',
        //     text: 'Procedure Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        {
          dataField: "start_date",
          text: "From",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "8%" };
          },
        },
        {
          dataField: "end_date",
          text: "To",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "8%" };
          },
        },
        {
          dataField: "type",
          text: "Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "8%" };
          },
        },
        {
          dataField: "phase",
          text: "Phase",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "8%" };
          },
        },
        {
          dataField: "role_id",
          text: "Role",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "8%" };
          },
        },
        {
          dataField: "no_of_study_locations",
          text: "No. of locations",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        {
          dataField: "conditions",
          text: "Condition",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        // {
        //     dataField: 'conditions',
        //     text: 'Condition',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        {
          dataField: "treatment_intervention",
          text: "Intervention",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        // {
        //     dataField: 'primary_outcomes',
        //     text: 'Primary Outcomes',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'secondary_outcomes',
        //     text: 'Secondary Outcomes',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        {
          dataField: "sponsors",
          text: "Sponsors",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        {
          dataField: "collaborators",
          text: "Collaborators",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { borderColor: "#02365F", width: "10%" };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //     },
        // },
        //         {
        //             dataField: 'source_link',
        //             text: 'Source Link',
        //             // align: 'center',
        //             headerStyle: (colum, colIndex) => {
        //                 return { borderColor:'#02365F',width: '10%' };
        //             },

        //             formatter: (cell, row) => {
        //               return(
        //               <div >

        //                 <a href={row.source_link}target="_blank"  style={{ color: 'blue', fontSize:'12px',textDecoration: 'underline', cursor: 'pointer', position:'relative',display:'inline-block'
        // }}
        //   > {row.source_link} </a>

        //                 </div>
        //               )

        //              },
        //         },
        {
          dataField: "source_link",
          text: "NCT ID",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
      ],
      columnsEventsDetails: [
        {
          dataField: "events",
          text: "Event Name",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "event_type",
          text: "Event Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        // {
        //     dataField: 'speciality_type',
        //     text: 'Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'sub_speciality',
        //     text: 'Sub Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'procedure_type',
        //     text: 'Procedure Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        {
          dataField: "event_desc",
          text: "Event Description",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "event_topic",
          text: "Event Topic",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "conditions",
          text: "Conditions",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "intervention",
          text: "Interventions",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "date",
          text: "Date",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "8%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "session_type",
          text: "Session Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "role_id",
          text: "Role",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "8%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "event_sponsor",
          text: "Sponsor",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "15%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "total_event_attendees",
          text: "Total Event Attendees",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "15%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "country_name",
          text: "Country",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        // {
        //     dataField: 'source_link',
        //     text: 'Source Link',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '15%' };
        //     },
        // },
      ],
      columnsGrants: [
        {
          dataField: "company",
          text: "Company",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "title",
          text: "Title",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "year",
          text: "Year",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "amount",
          text: "Amount",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "co_investigator",
          text: "Co-Investigator",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
      ],
      columnsGuidelines: [
        {
          dataField: "year",
          text: "Year",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        // {
        //     dataField: 'speciality_type',
        //     text: 'Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'sub_speciality',
        //     text: 'Sub Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '15%' };
        //     },
        // },
        // {
        //     dataField: 'procedure_type',
        //     text: 'Procedure Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '15%' };
        //     },
        // },
        {
          dataField: "organization",
          text: "Organization",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "type_of_organization",
          text: "Type of Organization",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },

        {
          dataField: "title",
          text: "Title",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "role",
          text: "Role",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },

        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return {width: '8%' };
        //     },
        // },
      ],
      columnsHospitalAffiliations: [
        {
          dataField: "hospital",
          text: "Hospital",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        // new
        {
          dataField: "role",
          text: "Role",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        // new
        {
          dataField: "from_year",
          text: "From",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        // new
        {
          dataField: "to_year",
          text: "To",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "city_name",
          text: "City",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "state_name",
          text: "State",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "country_name",
          text: "Country",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
      ],
      columnsInteractions: [
        {
          dataField: "date",
          text: "Date",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "opinion_leader",
          text: "Opinion Leader",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "emp_name",
          text: "Entered By",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "product",
          text: "Product",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "type",
          text: "Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "mode",
          text: "Mode",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "time",
          text: "Time",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "activity_or_tool_used",
          text: "Activity or Tool Used",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "13%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "comments",
          text: "Comments",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "13%", borderColor: "#02365F" };
          },
        },
      ],

      columnsPressItem: [
        {
          dataField: "title",
          text: "Title",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "25%", borderColor: "#02365F" };
          },
        },
        // new
        {
          dataField: "quote",
          text: "Quote",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "25%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "date",
          text: "Date",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "25%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "source_link",
          text: "Press Item Link",
          formatter: (cell, row) => {
            return (
              <div>
                <a
                  href={row.source_link}
                  target="_blank"
                  style={{
                    color: "#069",
                    fontSize: "12px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {row.source_link}{" "}
                </a>
              </div>
            );
          },
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "25%", borderColor: "#02365F" };
          },
        },
      ],
      columnsPresentation: [
        {
          dataField: "title",
          text: "Title",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        // new
        {
          dataField: "event_name",
          text: "Event Name",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "type",
          text: "Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "sponsor",
          text: "Sponsor",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "location",
          text: "Location",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "date",
          text: "Date",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
      ],
      columnsPublicationDetails: [
        {
          dataField: "journal",
          text: "Journal",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "12%", borderColor: "#02365F" };
          },
        },
        // {
        //     dataField: 'speciality_type',
        //     text: 'Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'sub_speciality',
        //     text: 'Sub Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        // {
        //     dataField: 'procedure_type',
        //     text: 'Procedure Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
        {
          dataField: "type_of_journal",
          text: "Type of Journal",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "12%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "publication_title",
          text: "Publication Title",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "15%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "date",
          text: "Date",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "12%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "position",
          text: "Position",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "12%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "co_author",
          text: "Co-Author",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "12%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "key_words",
          text: "Key Words",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "12%", borderColor: "#02365F" };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '8%' };
        //     },
        // },
        {
          dataField: "",
          text: "PMID",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "12%", borderColor: "#02365F" };
          },
        },
      ],
      columnsSunshinePayment: [
        {
          dataField: "date",
          text: "Date",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "type",
          text: "Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "devices",
          text: "Devices",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "location",
          text: "Location",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },

        {
          dataField: "amount",
          text: "Amount ($)",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "payment_type",
          text: "Payment Type",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "10%", borderColor: "#02365F" };
          },
        },
        {
          dataField: "OL_address",
          text: "Company Name",
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: "15%", borderColor: "#02365F" };
          },
        },
        // {
        //     dataField: 'speciality_type',
        //     text: 'Speciality Type',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '15%' };
        //     },
        // },
        // {
        //     dataField: 'NPI',
        //     text: 'NPI',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '10%' };
        //     },
        // },
      ],
    };
  }

  //Sub Navbar functions of summary piechart
  HomePiechartFun = async () => {
    this.setState({
      home_piechart_flag: true,
      showCompitiorTab: false,
      colabarationTab: false,
      activityTab: false,
    });
  };
  activityTabShow = () => {
    this.setState({
      home_piechart_flag: false,
      showCompitiorTab: false,
      colabarationTab: false,
      activityTab: true,
    });
  };
  EventPiechartFun = () => {
    this.setState({
      home_piechart_flag: false,
      event_piechart_flag: true,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
      activityTab: false,
    });
  };
  CompitiorAlignmentTab = () => {
    this.setState({
      showCompitiorTab: true,
      home_piechart_flag: false,
      colabarationTab: false,
      activityTab: false,
    });
  };
  PublicationPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: true,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  };
  AdboardPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: true,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  };
  GuidelinePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: true,
      clinicaltrial_piechart_flag: false,
    });
  };
  ClinicaltrialPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: true,
    });
  };

  // Function for drawer side selection
  PersonalInformationFun = async () => {
    await this.setState({
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PersonalInformationFlag: true,
    });
  };

  BiographyFun = async () => {
    // alert(this.state.kol_id);
    //alert( this.state.PersonalInformation.aggregate_score);
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      this.setState({
        PersonalInformationFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        BiographyFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  ProfessionalMembershipFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        ProfessionalMembershipFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };
  // Commented as per mail on 6th Feb 2024
  // EducationFun = async () => {
  //   if (this.state.PersonalInformation.aggregate_score !== "NA") {
  //     await this.setState({
  //       PersonalInformationFlag: false,
  //       BiographyFlag: false,
  //       ProfessionalMembershipFlag: false,
  //       AdvisoryBoardFlag: false,
  //       AwardsAndHonoursFlag: false,
  //       BooksAndMonographFlag: false,
  //       CommiteesFlag: false,
  //       ClinicalTrialDetailsFlag: false,
  //       EventsDetailsFlag: false,
  //       GrantsFlag: false,
  //       GuidelinesFlag: false,
  //       HospitalAffiliationsFlag: false,
  //       InteractionsFlag: false,
  //       PressItemFlag: false,
  //       PresentationsFlag: false,
  //       PublicationDetailsFlag: false,
  //       SunshinePaymentFlag: false,
  //       ProcedurePotentialFlag: false,
  //       EducationFlag: true,
  //       SummaryFlag: false,
  //       infoQuoteModal: false,
  //     });
  //   } else {
  //     this.setState({ infoQuoteModal: true });
  //   }
  // };

  AdvisoryBoardFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA" ) {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        AdvisoryBoardFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };
  // Commented as per mail on 6th Feb 2024
  // AwardsAndHonoursFun = async () => {
  //   if (this.state.PersonalInformation.aggregate_score !== "NA") {
  //     await this.setState({
  //       PersonalInformationFlag: false,
  //       BiographyFlag: false,
  //       ProfessionalMembershipFlag: false,
  //       EducationFlag: false,
  //       SummaryFlag: false,
  //       AdvisoryBoardFlag: false,
  //       BooksAndMonographFlag: false,
  //       CommiteesFlag: false,
  //       ClinicalTrialDetailsFlag: false,
  //       EventsDetailsFlag: false,
  //       GrantsFlag: false,
  //       GuidelinesFlag: false,
  //       HospitalAffiliationsFlag: false,
  //       InteractionsFlag: false,
  //       PressItemFlag: false,
  //       PresentationsFlag: false,
  //       PublicationDetailsFlag: false,
  //       SunshinePaymentFlag: false,
  //       ProcedurePotentialFlag: false,
  //       AwardsAndHonoursFlag: true,
  //       infoQuoteModal: false,
  //     });
  //   } else {
  //     this.setState({ infoQuoteModal: true });
  //   }
  // };
  // Commented as per mail on 6th Feb 2024
  // BooksAndMonographFun = async () => {
  //   if (this.state.PersonalInformation.aggregate_score !== "NA") {
  //     await this.setState({
  //       PersonalInformationFlag: false,
  //       BiographyFlag: false,
  //       ProfessionalMembershipFlag: false,
  //       EducationFlag: false,
  //       SummaryFlag: false,
  //       AdvisoryBoardFlag: false,
  //       AwardsAndHonoursFlag: false,
  //       CommiteesFlag: false,
  //       ClinicalTrialDetailsFlag: false,
  //       EventsDetailsFlag: false,
  //       GrantsFlag: false,
  //       GuidelinesFlag: false,
  //       HospitalAffiliationsFlag: false,
  //       InteractionsFlag: false,
  //       PressItemFlag: false,
  //       PresentationsFlag: false,
  //       PublicationDetailsFlag: false,
  //       SunshinePaymentFlag: false,
  //       ProcedurePotentialFlag: false,
  //       BooksAndMonographFlag: true,
  //       infoQuoteModal: false,
  //     });
  //   } else {
  //     this.setState({ infoQuoteModal: true });
  //   }
  // };

  CommiteesFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA" ) {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        CommiteesFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  ClinicalTrialDetailsFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA" ) {
      this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        ClinicalTrialDetailsFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  EventsDetailsFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        EventsDetailsFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  GrantsFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        GrantsFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  GuidelinesFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        GuidelinesFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };
  // Commented as per mail on 6th Feb 2024
  // HospitalAffiliationsFun = async () => {
  //   if (this.state.PersonalInformation.aggregate_score !== "NA") {
  //     await this.setState({
  //       PersonalInformationFlag: false,
  //       BiographyFlag: false,
  //       ProfessionalMembershipFlag: false,
  //       EducationFlag: false,
  //       SummaryFlag: false,
  //       AdvisoryBoardFlag: false,
  //       AwardsAndHonoursFlag: false,
  //       BooksAndMonographFlag: false,
  //       CommiteesFlag: false,
  //       ClinicalTrialDetailsFlag: false,
  //       EventsDetailsFlag: false,
  //       GrantsFlag: false,
  //       GuidelinesFlag: false,
  //       InteractionsFlag: false,
  //       PressItemFlag: false,
  //       PresentationsFlag: false,
  //       PublicationDetailsFlag: false,
  //       SunshinePaymentFlag: false,
  //       ProcedurePotentialFlag: false,
  //       HospitalAffiliationsFlag: true,
  //       infoQuoteModal: false,
  //     });
  //   } else {
  //     this.setState({ infoQuoteModal: true });
  //   }
  // };
  // Commented as per mail on 6th Feb 2024
  // InteractionsFun = async () => {
  //   if (this.state.PersonalInformation.aggregate_score !== "NA") {
  //     await this.setState({
  //       PersonalInformationFlag: false,
  //       BiographyFlag: false,
  //       ProfessionalMembershipFlag: false,
  //       EducationFlag: false,
  //       SummaryFlag: false,
  //       AdvisoryBoardFlag: false,
  //       AwardsAndHonoursFlag: false,
  //       BooksAndMonographFlag: false,
  //       CommiteesFlag: false,
  //       ClinicalTrialDetailsFlag: false,
  //       EventsDetailsFlag: false,
  //       GrantsFlag: false,
  //       GuidelinesFlag: false,
  //       HospitalAffiliationsFlag: false,
  //       PressItemFlag: false,
  //       PresentationsFlag: false,
  //       PublicationDetailsFlag: false,
  //       SunshinePaymentFlag: false,
  //       ProcedurePotentialFlag: false,
  //       InteractionsFlag: true,
  //       infoQuoteModal: false,
  //     });
  //   } else {
  //     this.setState({ infoQuoteModal: true });
  //   }
  // };
  // Commented as per mail on 6th Feb 2024
  // PressItemFun = async () => {
  //   if (this.state.PersonalInformation.aggregate_score !== "NA") {
  //     await this.setState({
  //       PersonalInformationFlag: false,
  //       BiographyFlag: false,
  //       ProfessionalMembershipFlag: false,
  //       EducationFlag: false,
  //       SummaryFlag: false,
  //       AdvisoryBoardFlag: false,
  //       AwardsAndHonoursFlag: false,
  //       BooksAndMonographFlag: false,
  //       CommiteesFlag: false,
  //       ClinicalTrialDetailsFlag: false,
  //       EventsDetailsFlag: false,
  //       GrantsFlag: false,
  //       GuidelinesFlag: false,
  //       HospitalAffiliationsFlag: false,
  //       InteractionsFlag: false,
  //       PresentationsFlag: false,
  //       PublicationDetailsFlag: false,
  //       SunshinePaymentFlag: false,
  //       ProcedurePotentialFlag: false,
  //       PressItemFlag: true,
  //       infoQuoteModal: false,
  //     });
  //   } else {
  //     this.setState({ infoQuoteModal: true });
  //   }
  // };
  // Commented as per mail on 6th Feb 2024
  // PresentationsFun = async () => {
  //   if (this.state.PersonalInformation.aggregate_score !== "NA") {
  //     await this.setState({
  //       PersonalInformationFlag: false,
  //       BiographyFlag: false,
  //       ProfessionalMembershipFlag: false,
  //       EducationFlag: false,
  //       SummaryFlag: false,
  //       AdvisoryBoardFlag: false,
  //       AwardsAndHonoursFlag: false,
  //       BooksAndMonographFlag: false,
  //       CommiteesFlag: false,
  //       ClinicalTrialDetailsFlag: false,
  //       EventsDetailsFlag: false,
  //       GrantsFlag: false,
  //       GuidelinesFlag: false,
  //       HospitalAffiliationsFlag: false,
  //       InteractionsFlag: false,
  //       PressItemFlag: false,
  //       PublicationDetailsFlag: false,
  //       SunshinePaymentFlag: false,
  //       ProcedurePotentialFlag: false,
  //       PresentationsFlag: true,
  //       infoQuoteModal: false,
  //     });
  //   } else {
  //     this.setState({ infoQuoteModal: true });
  //   }
  // };

  PublicationDetailsFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        PublicationDetailsFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  SunshinePaymentFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        ProcedurePotentialFlag: false,
        SunshinePaymentFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  ProcedurePotentialFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        SummaryFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  SummaryFun = async () => {
    if (this.state.PersonalInformation.aggregate_score !== "NA") {
      await this.setState({
        PersonalInformationFlag: false,
        BiographyFlag: false,
        ProfessionalMembershipFlag: false,
        EducationFlag: false,
        AdvisoryBoardFlag: false,
        AwardsAndHonoursFlag: false,
        BooksAndMonographFlag: false,
        CommiteesFlag: false,
        ClinicalTrialDetailsFlag: false,
        EventsDetailsFlag: false,
        GrantsFlag: false,
        GuidelinesFlag: false,
        HospitalAffiliationsFlag: false,
        InteractionsFlag: false,
        PressItemFlag: false,
        PresentationsFlag: false,
        PublicationDetailsFlag: false,
        SunshinePaymentFlag: false,
        ProcedurePotentialFlag: false,
        SummaryFlag: true,
        infoQuoteModal: false,
      });
    } else {
      this.setState({ infoQuoteModal: true });
    }
  };

  formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    if (phoneNumberLength == 10) {
      return `+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    } else if (phoneNumberLength == 11) {
      return `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(
        1,
        4
      )}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
    } else if (phoneNumberLength == 12) {
      return `+${phoneNumber.slice(0, 2)} ${phoneNumber.slice(
        2,
        5
      )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;
    } else if (phoneNumberLength > 5) {
      return +phoneNumber;
    }

    // if(phoneNumberLength == 10 )
    // {
    // return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    //   3,
    //   6
    // )}-${phoneNumber.slice(6, 10)}`;
    // }
    // else
    // {
    //   return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
    //     2,
    //     5
    //   )}-${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;

    // }
  }

  validate = () => {
    let noofkolErr = "";
    let noofbasickolErr = "";
    let noofdeepkolErr = "";
    let specilityErr = "";
    let procedureErr = "";
    let countryErr = "";
    let Multiple_single_profileErr = "";

    if (this.state.QuoteData.Multiple_single_profile == "BuyMultipleProfiles") {
      if (!this.state.QuoteData.Multiple_single_profile) {
        Multiple_single_profileErr = "Please select";
      }

      if (!this.state.QuoteData.no_of_basic_profile) {
        noofbasickolErr = "Please input no of basic profile";
      }

      if (!this.state.QuoteData.no_of_deep_profile) {
        noofdeepkolErr = "Please input no of deep profile";
      }

      if (this.state.QuoteData.countrieslist.length <= 0) {
        countryErr = "Please select country";
      }

      if (!this.state.QuoteData.specialty_id) {
        specilityErr = "Please select speciality";
      }

      // if (noofkolErr || procedureErr ||countryErr || specilityErr || profilingtypeErr || Multiple_single_profileErr) {
      //   this.setState({ noofkolErr, specilityErr ,procedureErr,countryErr,profilingtypeErr,Multiple_single_profileErr });
      //   return false;
      // }
      if (
        noofbasickolErr ||
        countryErr ||
        specilityErr ||
        noofdeepkolErr ||
        Multiple_single_profileErr
      ) {
        this.setState({
          noofbasickolErr,
          specilityErr,
          countryErr,
          noofdeepkolErr,
          Multiple_single_profileErr,
        });
        return false;
      }
    } else {
      if (!this.state.QuoteData.Multiple_single_profile) {
        Multiple_single_profileErr = "Please select";
      }
      if (Multiple_single_profileErr) {
        this.setState({ Multiple_single_profileErr });
        return false;
      }
    }

    return true;
  };

  onChangeValue = async (event) => {
    if (event.target.value == "BuyMultipleProfiles") {
      let { QuoteData } = this.state;
      QuoteData.kol_id = "";
      this.setState({ QuoteData });

      //alert(event.target.value);
      this.setState({
        multiplekolquotmodel: true,
        Multiple_single_profileErr: "",
      });
    } else {
      let { QuoteData } = this.state;
      QuoteData.kol_id = this.state.kol_id;
      QuoteData.no_of_kol = "";
      QuoteData.no_of_deep_profile = "";
      QuoteData.no_of_basic_profile = "";
      QuoteData.specialty_id = "";
      QuoteData.procedure_type = "";
      // QuoteData.Profilingtype='';
      QuoteData.countrieslist = "";
      this.setState({ QuoteData });

      // let { QuoteData } = this.state;
      // QuoteData.kol_id =this.state.kol_id;
      // this.setState({ QuoteData });

      //alert(event.target.value);
      this.setState({
        multiplekolquotmodel: false,
        //noofkolErr: '',
        noofbasickolErr: "",
        noofdeepkolErr: "",
        specilityErr: "",
        procedureErr: "",
        countryErr: "",
        Multiple_single_profileErr: "",
        // profilingtypeErr:'',
      });
    }

    // alert(event.target.value);
    let { QuoteData } = this.state;
    QuoteData.Multiple_single_profile = event.target.value;
    this.setState({ QuoteData });
    console.log(event.target.value);
  };

  SaveQuoteinfo = async () => {
    const isValid = this.validate();
    //  const isValid =true;
    if (isValid) {
      this.state.QuoteData.no_of_kol =
        Number(this.state.QuoteData.no_of_basic_profile) +
        Number(this.state.QuoteData.no_of_deep_profile);

      this.setState({
        ShowBar: true,
      });

      const url = global.websiteUrl + "/service/KOL_landing/save_quote_data";
      let formData = new FormData();

      formData.append("Quote_Info", JSON.stringify(this.state.QuoteData));
      formData.append('token_id',this.state.client_token);


      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      };

      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message);

          // alert(response.data.status);
          // alert(response.data.quotation_id);

          this.setState({
            ShowBar: false,
          });
          if (response.data.status == true) {
            if (this.state.client_name != "Guest") {
              Swal.fire({
                icon: "success",
                //text: response.data.message,
                text: " Thanks for quote,Our experts will get back to you within 24 hours.!",
                confirmButtonColor: "#d33",
                confirmButtonText: "OK",
              });

              this.setState({ infoQuoteModal: false, QuoteModal: false });
            } else {
              // alert(response.data.quotation_id);
              sessionStorage.setItem(
                "quotation_id",
                response.data.quotation_id
              );
              this.setState({
                infoQuoteModal: false,
                QuoteModal: false,
                SignupModal: true,
                quotation_id: response.data.quotation_id,
              });

              // this.setState({ RedirectSignUp: true })
            }
          } else {
            Swal.fire({
              icon: "error",
              text: response.data.message,
              confirmButtonColor: "#d33",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.setState({
        pnameErr: "",
        psowErr: "",
      });
    }
  };

  redirectknowmore = async () => {
    this.setState({ redirectknowmore: true });
    // alert('redirectknowmorehi');
  };

  getCombinedData = (dataObject) => {
    let obj = {
      Lifetime: 0,
      latest_year: 0,
      mid_year: 0,
      last_year: 0,
    };
    Object.keys(dataObject).forEach((item) => {
      if (item !== "year") {
        Object.keys(dataObject[item]).forEach((e) => {
          obj[e] = obj[e] + dataObject[item][e];
        });
      }
    });
    console.log(obj, "obj");
    return obj;
  };

  componentDidMount() {


    let getSession_wtoken = sessionStorage.getItem('wtoken')
    console.log('tok5', getSession_wtoken);

    jwt.verify(getSession_wtoken, jwtkey.jwt.key, async (err, response) => {
     
      if (!err && (response.login_type =="client" || response.login_type =="client_demo"))
        {
         
            let getSession_Client_token =sessionStorage.getItem('token')
        
            if (getSession_Client_token !== null) {
              this.setState({
                client_token: getSession_Client_token,
              })
            }

          
            let getSession_Client_Name = sessionStorage.getItem("name");
            if (getSession_Client_Name !== null) {
              this.setState({
                client_name: getSession_Client_Name,
              });
            }

            let getSession_Kol_Id = sessionStorage.getItem("kol_id");
            if (getSession_Kol_Id !== null) {
              this.setState({
                kol_id: JSON.parse(getSession_Kol_Id),
              });
            }
            else {
              this.setState({ redirect: true });
            }

            console.log("kol_id", this.state.kol_id);
            console.log("getSession_Kol_Id", getSession_Kol_Id);
            this.setState({
              // LoadingBar:true,
              ShowBar: true,
            });
            const formdata = new FormData()
            formdata.append("kol_id", JSON.parse(getSession_Kol_Id));
            formdata.append('token_id',getSession_Client_token);
            const options = {
              method: "POST",
            };
            const starOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
            };
            // Star DOL Senction
            // https://www.cetas.medtech-gurus.com/Cetas/service/RankingCalculation/Get_Star
            fetch(global.websiteUrl + "/service/Client/Get_Star", starOptions)
              .then((Response) => Response.json())
              .then((findresponse) => {
                console.log({ result: findresponse.result });
                var starData = findresponse.result[0];
                console.log({ starData: JSON.stringify(starData) })
                this.setState({
                  dol: starData.dol_type && starData.dol_type !== "false" ? starData.dol_type : false,
                  sanction: starData.sanction_status && starData.sanction_status !== "false" ? starData.sanction_status : false,
                  star: starData.star_type && starData.star_type !== "false" ? starData.star_type : false
                });
              });
            console.log({ star: this.state.star })
            console.log({ sanction: this.state.sanction })
            console.log({ dol: this.state.dol })

            // focus area
            fetch(global.websiteUrl + "/service/Client/get_focus_area", starOptions)
              .then((Response) => Response.json())
              .then((findresponse) => {
                console.log(findresponse);
                // Find the array containing max number of element
                let focusAreaLength = findresponse.Premium_Insights.Focus_Areas.length
                  , focusConditionLength = findresponse.Premium_Insights.focus_condition.length
                  , focusProcedureLength = findresponse.Premium_Insights.focus_procedure.length
                  , rowCount = [focusAreaLength, focusConditionLength, focusProcedureLength].sort().pop()
                let premiumInsightDetails = []
                const focusAreaList = findresponse.Premium_Insights.Focus_Areas
                  , focusCondtionList = findresponse.Premium_Insights.focus_condition
                  , focusProcedureList = findresponse.Premium_Insights.focus_procedure
                // create table with max rowcount
                for (let i = 0; i < rowCount; i++) {
                  console.log(focusAreaList[i])
                  premiumInsightDetails[i] = {
                    rank: focusAreaList[i] ? focusAreaList[i].rank : 3
                    , maping_area: focusAreaList[i] ? focusAreaList[i].maping_area : ""
                    , maping_condition: focusCondtionList[i] ? focusCondtionList[i].maping_condition : ""
                    , procedure_type: focusProcedureList[i] ? focusProcedureList[i].procedure_type : ""
                  }
                }
                console.log(premiumInsightDetails, "premiumInsightDetails")
                console.log("focusProcedureList", JSON.stringify(focusProcedureList));
                console.log("focusCondtionList", JSON.stringify(focusCondtionList));
                console.log("focusAreaList", JSON.stringify(focusAreaList));
                this.setState({
                  premiumInsightDetails: premiumInsightDetails,
                  focusAreas: focusAreaList,
                  focusConditions: focusCondtionList,
                  focusProcedures: focusProcedureList,
                });
              });
            // Focus Area

             const url = global.websiteUrl + "/service/Client/get_ClientKOLDetails";
            
        
            //const url = global.websiteUrl + '/service/Client/get_KOL_details_forprofile';
            let formData = new FormData();
            // formData.append('kol_id','CHCUI001');
            formData.append("kol_id", JSON.parse(getSession_Kol_Id));
            formData.append('token_id',getSession_Client_token);

            const config = {
              //   headers: { 'content-type': 'multipart/form-data' }
            };
            const host = window.location.origin
              , imagePath = `${host}/ProfileImage/`
            axios.post(url, formData, config).then((response) => {
              // console.log(response);
              var lgdata = response.data;
              // console.log("lgdata=====", lgdata);
              var KOL_Personal_Info = response.data.KOL_Personal_Info;
              // console.log(
              //   "KOL_Personal_Info==========",
              //   JSON.stringify(KOL_Personal_Info)
              // );
              var KOL_Work_Details = response.data.KOL_Work_Details;
              // console.log(
              //   "KOL_Work_Details==========",
              //   JSON.stringify(KOL_Work_Details)
              // );
              this.setState({
                PersonalInformation: KOL_Personal_Info,
                WorkDetails: response.data.KOL_Work_Details,
                Biography: response.data.KOL_Biography,
                ProfessionalMemberships: response.data.KOL_Professional_Memberships,
                Education: response.data.KOL_Education,
                AdvisoryBoard: response.data.KOL_Advisory_Board,
                AwardsHonours: response.data.KOL_Awards_Honours,
                BooksMonographs: response.data.KOL_Books_Monographs,
                Commitees: response.data.KOL_Commitees,
                ClinicalTrialDetails: response.data.KOL_Clinical_Trial_Details,
                EventsDetails: response.data.KOL_Events_Details,
                Grants: response.data.KOL_Grants,
                Guidelines: response.data.KOL_Guidelines,
                HospitalAffiliations: response.data.KOL_Hospital_Affiliations,
                Interactions: response.data.KOL_Interactions,
                PressItem: response.data.KOL_Press_Item,
                Presentation: response.data.KOL_Presentations,
                PublicationDetails: response.data.KOL_Publication_Details,
                SunshinePayment: response.data.KOL_Sunshine_Payment,

                profile_photo_for_edit: `${imagePath + response.data.KOL_Profile_Photo}`,
                BiographyResume_edit: response.data.KOL_Resume,
                BiographyResumeFileName: response.data.KOL_Resume,

                profile_photo: `${imagePath + response.data.KOL_Profile_Photo}`,
                // BiographyResume: response.data.KOL_Resume,
              });

              this.setState({
                ShowBar: false,
                // ContractFileName: ContractFileNameapi
              });
              console.log("PersonalInformation", this.state.PersonalInformation);
              console.log("profile_photo_for_edit", this.state.profile_photo_for_edit);
              console.log("Education", this.state.Education);
              console.log("WorkDetails===============", this.state.WorkDetails);

            
              if (this.state.PersonalInformation.doctor_email2 !== "") {
                this.setState({
                  secondryEmail: ", " + this.state.PersonalInformation.doctor_email2,
                });
              }

              this.setState({
                DoctorCompleteName:
                  this.state.PersonalInformation.salutation +
                  " " +
                  this.state.PersonalInformation.doctor_full_name +
                  ", " +
                  this.state.PersonalInformation.suffix,
              });

              this.setState({
                ranking: this.state.PersonalInformation.aggregate_score,
              });
              // alert(this.state.DoctorCompleteName);

              if (
                this.state.PersonalInformation.aggregate_score == "NA" ||
                this.state.PersonalInformation.aggregate_score == " "
              ) {
                this.setState({ lock_unlock_flag: true });
              }

            });

            // Activity Trends api
            const activityTrendsUrl = global.websiteUrl + "/service/Client/get_activity_trends"
              , activityData = new FormData();
            activityData.append("kol_id", JSON.parse(getSession_Kol_Id))
            activityData.append('token_id',getSession_Client_token);

            axios
              .post(activityTrendsUrl, activityData, config)
              .then((response) => {
                console.log("response of report api", response.data);
                this.setState({
                  clientTrends: {
                    // year: response.data.Activity_Trends.Advisory.map(a => a.year),
                    publications: response.data.Activity_Trends.Publication //this.getCombinedData(response.data.PublicationsChart),
                    , clinicalTrails: response.data.Activity_Trends.Trial
                    , guideLines: response.data.Activity_Trends.Guideline
                    , eventsConference: response.data.Activity_Trends.Event
                    , advisoryBoard: response.data.Activity_Trends.Advisory
                  },
                });

              })
              .catch((error) => {
                console.log(error);
              });



            }
            else {
              // console.log('tok555', response);

              // console.error('error', err.message);
              Swal.fire({
                icon: 'error',
                title: jwtkey.jwt.session_expired_msg_title,
                text: jwtkey.jwt.wrong_url_msg,
                // showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
              sessionStorage.removeItem('token');
              sessionStorage.removeItem('wtoken');
              sessionStorage.removeItem('id');
              sessionStorage.removeItem('name');
              sessionStorage.removeItem('email');
              sessionStorage.removeItem('login_type');              
              sessionStorage.removeItem("speciality_id");
              this.setState({ redirect: true })
            }
          }) 

      
  
  }

  

  // search modal functions
  toggleinfoQuoteModal = () => {
    this.setState({
      infoQuoteModal: !this.state.infoQuoteModal,
    });
  };

  // rediect funcs
  BackToDashboard() {
    this.setState({ redirectBackToDashboard: true });
  }

  BackToKolList() {
    this.setState({ redirectBackToKolList: true });
  }

  profileInfoNav() {
    sessionStorage.setItem("kol_id", JSON.stringify(this.state.kol_id));
    console.log("Navigation of clientKolProfile", this.state.kol_id);
    this.setState({
      redirectClientKolProfile: true,
    });
  }

  bioViewNav() {
    sessionStorage.setItem("kol_id", JSON.stringify(this.state.kol_id));
    console.log("Navigation of ClientViewBio", this.state.kol_id);
    this.setState({
      redirectClientViewBiography: true,
    });
  }

  render() {
    if (this.state.redirectClientKolProfile) {
      return (
        <Redirect
          to={{
            pathname: "/Profile Export",
          }}
        />
      );
    }
    if (this.state.redirectClientViewBiography) {
      return (
        <Redirect
          to={{
            pathname: "/Bio Export",
          }}
        />
      );
    }
    if (this.state.redirectBackToKolList) {
      return (
        <Redirect
          to={{
            pathname: "/My Database",
          }}
        />
      );
    }

    if (this.state.redirectBackToDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/My Dashboard",
          }}
        />
      );
    }

    if (this.state.redirectknowmore) {
      return (
        <Redirect
          to={{
            pathname: "/Profiletypedetails",
          }}
        />
      );
    }

    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) => (
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}
      </option>
    ));

    // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) => (
      <option key={procedure_list.id} value={procedure_list.procedure_type}>
        {procedure_list.procedure_type}
      </option>
    ));

    //************ New Chart Data */

    const EngagementSumChartData = [
      // ["Event", this.state.ESChart.EngagementSumLifetime,{role:"annotation"},this.state.ESChart.EngagementSumYear1,{role:"annotation"},this.state.ESChart.EngagementSumYear2,{role:"annotation"},this.state.ESChart.EngagementSumYear3,{role:"annotation"}],

      //  ["Event", "Lifetime",{role:"annotation"},"2019",{role:"annotation"},"2020",{role:"annotation"},"2021",{role:"annotation"}],

      //   ["Overall", 43,43,24,24,14,14,40,40],
      //   ["Trial", 43,43,24,24,14,14,40,40],
      //   ["Guideline", 25,25,44,44,24,24,34,34],
      //   ["Event",35,35,18,18,13,13,23,23],
      //   ["Publication",35, 35,28,28,45,45,48,48],
      //   ["Advisory",25, 25,44,44,22,22,45,45]

      [
        "Event",
        "Lifetime",
        { role: "annotation" },
        this.state.latest_year,
        { role: "annotation" },
        this.state.mid_year,
        { role: "annotation" },
        this.state.last_year,
        { role: "annotation" },
      ],
      ["Overall", 43, 43, 24, 24, 14, 14, 40, 40],
      ["Trial", 43, 43, 24, 24, 14, 14, 40, 40],
      ["Guideline", 25, 25, 44, 44, 24, 24, 34, 34],
      ["Event", 35, 35, 18, 18, 13, 13, 23, 23],
      ["Publication", 35, 35, 28, 28, 45, 45, 48, 48],
      //["Advisory",this.state.engagementSummary.kol_advisory_board.count_latest_year, 25,44,44,22,22,45,45]
    ];

    const EngagementSumChartOptions = {
      vAxis: {
        maxValue: 100,
        title: "Percentile",
        //title:this.state.engagementSummary.year.last_year,
        //title:this.state.engagementSummary.year.last_year,

        //format: 'percent',

        titleTextStyle: {
          // fontName : "Oswald",
          //italic : false,
          color: this.state.vAxisfontcolor,
          bold: this.state.vAxisfontbold,
          fontSize: this.state.vAxisfontsize,
        },

        gridlines: { count: 0 },
      },

      hAxis: {
        textStyle: {
          bold: this.state.hAxisfontbold,
          fontSize: this.state.hAxisfontsize,
          color: this.state.hAxisfontcolor,
        },
      },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      chartArea: {
        width: this.state.chartAreawidth,
        height: this.state.chartAreaheight,
      },

      title: "Engagement Summary",
      //titlePosition: 'Center',

      //bar: {width:"65%",gap:"30%"},
      bar: { width: "75%" },

      legend: {
        position: "bottom",
        width: 2,
        textStyle: {
          fontSize: this.state.legendfontsize,
          color: this.state.legendfontcolor,
          bold: this.state.legendfontbold,
        },
      },

      //isStacked:'relative',
      //isStacked: 'percent',

      annotations: {
        alwaysOutside: true,
        highContrast: true,
        textStyle: {
          color: this.state.annotationfontcolor,
          fontSize: this.state.annotationfontsize,
          bold: this.state.annotationfontbold,
        },
      },

      series: {
        0: { color: "#01345C" },
        1: { color: "#A55B52" },
        2: { color: "#035B96" },
        3: { color: "#D7B4B0" },
      },
    };

    // ************ Recent Trend Chart  ***************

    const RecentTrendChartData = [
      [
        "",
        "2018",
        { role: "annotation" },
        "2019",
        { role: "annotation" },
        "2020",
        { role: "annotation" },
      ],

      ["Clinical Trials", 1, 1, 2, 2, 2, 2],
      ["Guidelines", 1, 1, 0, 0, 1, 1],
      ["Events/Conferences", 5, 5, 7, 7, 8, 8],
      ["Publication", 50, 50, 45, 45, 40, 40],
      ["Advisory", 0, 0, 1, 1, 1, 1],
    ];

    const RecentTrendChartOptions = {
      vAxis: {
        maxValue: 100,
        //title: 'Percentile',
        //format: 'percent',

        titleTextStyle: {
          // fontName : "Oswald",
          //italic : false,
          color: this.state.vAxisfontcolor,
          bold: this.state.vAxisfontbold,
          fontSize: this.state.vAxisfontsize,
        },

        gridlines: { count: 0 },
      },

      chartArea: {
        width: this.state.chartAreawidth,
        height: this.state.chartAreaheight,
      },

      hAxis: {
        textStyle: {
          bold: this.state.hAxisfontbold,
          fontSize: this.state.hAxisfontsize,
          color: this.state.hAxisfontcolor,
        },
      },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Recent Trends",

      //bar: {width:"65%",gap:"30%"},
      bar: { width: "25%", gap: "35%" },

      legend: {
        position: "bottom",
        width: 2,
        textStyle: {
          fontSize: this.state.legendfontsize,
          color: this.state.legendfontcolor,
          bold: this.state.legendfontbold,
        },
      },

      //isStacked:'relative',
      //isStacked: 'percent',

      annotations: {
        alwaysOutside: true,
        highContrast: true,
        textStyle: {
          color: this.state.annotationfontcolor,
          fontSize: this.state.annotationfontsize,
          bold: this.state.annotationfontbold,
        },
      },

      series: {
        0: { color: "#01345C" },
        1: { color: "#A55B52" },
        2: { color: "#035B96" },
        3: { color: "#D7B4B0" },
      },
    };

    ///************ Event/Confrence Chart  */

    // const EventConfrenceGeographicChartData=

    //    [

    //   //     [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
    //   //     ["Lifetime",this.state.EventsGeographicLocal.event_Scope_Lifetime,this.state.EventsGeographicLocal.event_Scope_Lifetime+'%'	,this.state.EventsGeographicLocal.event_Scope_latest_year,this.state.EventsGeographicLocal.event_Scope_latest_year+'%',this.state.EventsGeographicLocal.event_Scope_mid_year,this.state.EventsGeographicLocal.event_Scope_mid_year+'%',	this.state.EventsGeographicLocal.event_Scope_last_year,this.state.EventsGeographicLocal.event_Scope_last_year+'%'],
    //   //     ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
    //   //     ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
    //   //     ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'],

    //  ]

    const EventConfrenceGeographicChartOptions = {
      vAxis: {
        //maxValue: 10,
        //title: 'Percentile',
        format: "percent",

        titleTextStyle: {
          // fontName : "Oswald",
          //italic : false,
          color: this.state.vAxisfontcolor,
          bold: this.state.vAxisfontbold,
          fontSize: this.state.vAxisfontsize,
        },

        gridlines: { count: 0 },
      },

      chartArea: {
        width: this.state.chartAreawidth,
        height: this.state.chartAreaheight,
      },

      hAxis: {
        textStyle: {
          bold: this.state.hAxisfontbold,
          fontSize: this.state.hAxisfontsize,
          color: this.state.hAxisfontcolor,
        },
      },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Geographic Scope of the Speaking Engagements",

      //bar: {width:"65%",gap:"30%"},
      bar: { width: "75%", gap: "40%" },

      legend: {
        position: "bottom",
        width: 2,
        textStyle: {
          fontSize: this.state.legendfontsize,
          color: this.state.legendfontcolor,
          bold: this.state.legendfontbold,
        },
      },

      //isStacked:'relative',
      //isStacked: 'percent',
      isStacked: "percent",

      annotations: {
        // alwaysOutside: true,
        // alwaysInnerside:true,
        highContrast: true,
        textStyle: {
          color: this.state.annotationfontcolor,
          fontSize: this.state.annotationfontsize,
          bold: this.state.annotationfontbold,
        },
      },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    // Event Role

    // const EventConfrenceRoleChartData=

    //   [
    //     [ "","Event Chair",{role:"annotation"},"Moderator/Chair",{role:"annotation"},	"Regular",{role:"annotation"},"Keynote Speaker",{role:"annotation"}],

    //      // [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
    //       ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
    //       ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
    //       ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
    //       ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'],

    //   ]

    const EventConfrenceRoleChartOptions = {
      vAxis: {
        //maxValue: 10,
        //title: 'Percentile',
        format: "percent",

        titleTextStyle: {
          // fontName : "Oswald",
          //italic : false,
          color: this.state.vAxisfontcolor,
          bold: this.state.vAxisfontbold,
          fontSize: this.state.vAxisfontsize,
        },

        gridlines: { count: 0 },
      },
      chartArea: {
        width: this.state.chartAreawidth,
        height: this.state.chartAreaheight,
      },

      hAxis: {
        textStyle: {
          bold: this.state.hAxisfontbold,
          fontSize: this.state.hAxisfontsize,
          color: this.state.hAxisfontcolor,
        },
      },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Role of the Speaking Engagements",

      //bar: {width:"65%",gap:"30%"},
      bar: { width: "75%", gap: "40%" },

      legend: {
        position: "bottom",
        width: 2,
        textStyle: {
          fontSize: this.state.legendfontsize,
          color: this.state.legendfontcolor,
          bold: this.state.legendfontbold,
        },
      },

      isStacked: "relative",
      //isStacked: 'percent',

      annotations: {
        // alwaysOutside: true,
        highContrast: true,
        textStyle: {
          color: this.state.annotationfontcolor,
          fontSize: this.state.annotationfontsize,
          bold: this.state.annotationfontbold,
        },
      },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    // Publication ***************

    // const PublicationGeographicChartData=

    //   [

    //       [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
    //       ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
    //       ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
    //       ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
    //       ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'],

    //   ]

    const commonChartOptions = {
      legend: {
        display: true,
        position: "none",
      },
      hAxis: {
        title: "Value"
      },
      vAxis: {
        title: "Year",
      },
      titlePosition: "none",
      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    const PublicationGeographicChartOptions = {
      legend: {
        display: false,
        position: "none",
      },

      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      // format: "percent",

      // titleTextStyle: {
      //   // fontName : "Oswald",
      //   //italic : false,
      //   color: this.state.vAxisfontcolor,
      //   bold: this.state.vAxisfontbold,
      //   fontSize: this.state.vAxisfontsize,
      // },

      //   gridlines: { count: 0 },
      // },
      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },
      titlePosition: "none",
      title: "Publications",

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // isStacked: "relative",
      // isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    // const PublicationProminenceRoleChartData=

    // [
    //   [ "","Other Author",{role:"annotation"},"Second Author",{role:"annotation"},	"First Author",{role:"annotation"}],

    //    // [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
    //     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%'],
    //     ["2020",25,25+'%',	30,30+'%',	30,30+'%'],
    //     ["2019",20,20+'%',	30,30+'%',35,35+'%'],
    //     ["2018",20,20+'%',40,40+'%',30,30+'%'],

    // ]

    const PublicationProminenceRoleChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Role Prominence: Publication",

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    // *************** Clinical Trial *********************

    //const ClinicalTrialsGeographicChartData=

    // [
    //   [ "","more than 50",{role:"annotation"},"11-50",{role:"annotation"},"1-10",{role:"annotation"},	"1",{role:"annotation"}],

    //     [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
    //     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
    //     ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
    //     ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
    //     ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'],

    // ]

    const ClinicalTrialsGeographicChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Geographic Scope: Clinical Trials",

      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      // //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    // const ClinicalTrialsLocationChartData=

    // [
    //   [ "","more than 50",{role:"annotation"},"11-50",{role:"annotation"},"1-10",{role:"annotation"},	"1",{role:"annotation"}],

    //     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
    //     ["2020",25,25+'%',	30,30+'%',	30,30+'%',	10,10+'%'],
    //     ["2019",20,20+'%',	30,30+'%',35,35+'%',	10,10+'%'],
    //     ["2018",20,20+'%',40,40+'%',30,30+'%',	10,10+'%'],

    // ]

    const ClinicalTrialsLocationChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Trial Location Prominence: Clinical Trials",

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    const ClinicalTrialsTypeChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Clinical Trials Type ",

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };
    // *************** Advisory Board ********************

    // const AdvisoryBoardGeographicChartData=

    //   [

    //       [ "","Global",{role:"annotation"},"Regional",{role:"annotation"},	"National",{role:"annotation"},"Local",{role:"annotation"}],
    //       ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',	10,10+'%'],
    //       ["2020",25,25+'%',	30,30+'%',	30,30+'%',	15,15+'%'],
    //       ["2019",20,20+'%',	30,30+'%',35,35+'%',15,15+'%'],
    //       ["2018",20,20+'%',40,40+'%',30,30+'%',10,10+'%'],

    //   ]

    const AdvisoryBoardGeographicChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Geographic Scope: Advisory Board",

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    // const AdvisoryBoardRoleProminenceChartData=

    // [
    //   [ "","committee Member",{role:"annotation"},"Co Chair",{role:"annotation"},"Chair",{role:"annotation"}],

    //     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%'],
    //     ["2020",25,25+'%',	30,30+'%',	30,30+'%'],
    //     ["2019",20,20+'%',	30,30+'%',35,35+'%'],
    //     ["2018",20,20+'%',40,40+'%',30,30+'%'],

    // ]

    const AdvisoryBoardRoleProminenceChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Role Prominence: Advisory Board",

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    // const AdvisoryBoardAffiliationTypeChartData=

    // [
    //   [ "","Goverment Institution",{role:"annotation"},"Professional Association",{role:"annotation"},"Medtech Companies",{role:"annotation"},"Pharma Companies",{role:"annotation"}],

    //     ["Lifetime",30,30+'%'	,40,40+'%',20,20+'%',20,20+'%'],
    //     ["2020",25,25+'%',	30,30+'%',	30,30+'%',20,20+'%'],
    //     ["2019",20,20+'%',	30,30+'%',35,35+'%',20,20+'%'],
    //     ["2018",20,20+'%',40,40+'%',30,30+'%',20,20+'%'],

    // ]

    const AdvisoryBoardAffiliationTypeChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Affiliation Type",

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    /// **************Guideline ****************

    const GuidelinesGeographicChartData = [
      [
        "",
        "Global",
        { role: "annotation" },
        "Regional",
        { role: "annotation" },
        "National",
        { role: "annotation" },
        "Local",
        { role: "annotation" },
      ],
      ["Lifetime", 30, 30 + "%", 40, 40 + "%", 20, 20 + "%", 10, 10 + "%"],
      ["2020", 25, 25 + "%", 30, 30 + "%", 30, 30 + "%", 15, 15 + "%"],
      ["2019", 20, 20 + "%", 30, 30 + "%", 35, 35 + "%", 15, 15 + "%"],
      ["2018", 20, 20 + "%", 40, 40 + "%", 30, 30 + "%", 10, 10 + "%"],
    ];

    const GuidelinesGeographicChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      title: "Geographic Scope: Guidelines",

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },

      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },
      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },
      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    const GuidelinesRoleProminenceChartData = [
      [
        "",
        "Committee Member",
        { role: "annotation" },
        "Committee Chair",
        { role: "annotation" },
        "Author",
        { role: "annotation" },
      ],

      ["Lifetime", 30, 30 + "%", 40, 40 + "%", 20, 20 + "%"],
      ["2020", 25, 25 + "%", 30, 30 + "%", 30, 30 + "%"],
      ["2019", 20, 20 + "%", 30, 30 + "%", 35, 35 + "%"],
      ["2018", 20, 20 + "%", 40, 40 + "%", 30, 30 + "%"],
    ];

    const GuidelinesRoleProminenceChartOptions = {
      // vAxis: {
      //   //maxValue: 10,
      //   //title: 'Percentile',
      //   format: "percent",

      //   titleTextStyle: {
      //     // fontName : "Oswald",
      //     //italic : false,
      //     color: this.state.vAxisfontcolor,
      //     bold: this.state.vAxisfontbold,
      //     fontSize: this.state.vAxisfontsize,
      //   },

      //   gridlines: { count: 0 },
      // },

      // hAxis: {
      //   textStyle: {
      //     bold: this.state.hAxisfontbold,
      //     fontSize: this.state.hAxisfontsize,
      //     color: this.state.hAxisfontcolor,
      //   },
      // },

      // titleTextStyle: {
      //   bold: this.state.titlefontbold,
      //   color: this.state.titlefontcolor,
      //   fontSize: this.state.titlefontsize,
      // },

      title: "Role Prominence: Guidelines",
      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      //bar: {width:"65%",gap:"30%"},
      // bar: { width: "75%", gap: "40%" },

      // legend: {
      //   position: "bottom",
      //   width: 2,
      //   textStyle: {
      //     fontSize: this.state.legendfontsize,
      //     color: this.state.legendfontcolor,
      //     bold: this.state.legendfontbold,
      //   },
      // },

      // isStacked: "relative",
      isStacked: "percent",

      // annotations: {
      //   // alwaysOutside: true,
      //   highContrast: true,
      //   textStyle: {
      //     color: this.state.annotationfontcolor,
      //     fontSize: this.state.annotationfontsize,
      //     bold: this.state.annotationfontbold,
      //   },
      // },
      // chartArea: {
      //   width: this.state.chartAreawidth,
      //   height: this.state.chartAreaheight,
      // },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    const GuidelinesAffiliationTypeChartData = [
      [
        "",
        "Goverment Institutions",
        { role: "annotation" },
        "Professional Association",
        { role: "annotation" },
        "Academic/Hospital",
        { role: "annotation" },
      ],

      ["Lifetime", 30, 30 + "%", 40, 40 + "%", 20, 20 + "%"],
      ["2020", 25, 25 + "%", 30, 30 + "%", 30, 30 + "%"],
      ["2019", 20, 20 + "%", 30, 30 + "%", 35, 35 + "%"],
      ["2018", 20, 20 + "%", 40, 40 + "%", 30, 30 + "%"],
    ];

    const GuidelinesAffiliationTypeChartOptions = {
      vAxis: {
        //maxValue: 10,
        //title: 'Percentile',
        format: "percent",

        titleTextStyle: {
          // fontName : "Oswald",
          //italic : false,
          color: this.state.vAxisfontcolor,
          bold: this.state.vAxisfontbold,
          fontSize: this.state.vAxisfontsize,
        },

        gridlines: { count: 0 },
      },

      hAxis: {
        textStyle: {
          bold: this.state.hAxisfontbold,
          fontSize: this.state.hAxisfontsize,
          color: this.state.hAxisfontcolor,
        },
      },

      titleTextStyle: {
        bold: this.state.titlefontbold,
        color: this.state.titlefontcolor,
        fontSize: this.state.titlefontsize,
      },

      chartArea: {
        width: this.state.chartAreawidth,
        height: this.state.chartAreaheight,
      },

      title: "Affiliation Type",

      //bar: {width:"65%",gap:"30%"},
      bar: { width: "75%", gap: "40%" },

      legend: {
        position: "bottom",
        width: 2,
        textStyle: {
          fontSize: this.state.legendfontsize,
          color: this.state.legendfontcolor,
          bold: this.state.legendfontbold,
        },
      },

      isStacked: "relative",
      //isStacked: 'percent',

      annotations: {
        // alwaysOutside: true,
        highContrast: true,
        textStyle: {
          color: this.state.annotationfontcolor,
          fontSize: this.state.annotationfontsize,
          bold: this.state.annotationfontbold,
        },
      },

      series: {
        0: { color: this.state.series1color },
        1: { color: this.state.series2color },
        2: { color: this.state.series3color },
        3: { color: this.state.series4color },
      },
    };

    let dataProfessionalMembership = this.state.ProfessionalMemberships.map(
      (dataProfessionalMembership) => {
        return (
          <tr key={dataProfessionalMembership.organization}>
            <td>{dataProfessionalMembership.organization}</td>
            <td>{dataProfessionalMembership.organization_type}</td>
            <td>{dataProfessionalMembership.source_link}</td>
            <td>{dataProfessionalMembership.location}</td>
            <td>{dataProfessionalMembership.position}</td>
            <td>{dataProfessionalMembership.tenure}</td>
            <td>{dataProfessionalMembership.organization_website}</td>
          </tr>
        );
      }
    );

    return (
      <div style={{ backgroundColor: "#F9F9F9", paddingBottom: "35px" }}>
        {this.state.ShowBar ? (
          <Modal isOpen={true} centered>
            <PropagateLoader color={"#A90007"} size="30px" loading="true" />
          </Modal>
        ) : null}
        {this.state.progressBar ? (
          <div style={{ position: "fixed", top: "0" }}>
            <LoadingBar
              progress="100"
              height={3}
              color="#A90007"
              transitionTime={4000}
              loaderSpeed={3000}
            />
          </div>
        ) : null}

        <ClientHeader />

        <div
          class="container-fluid"
          id="mainClientDashboard"
          style={{ width: "98%" }}
        >
          <div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="row breadcum">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  <h4>
                    <b>Profile Details</b>
                  </h4>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                  {/* <h5> <label className='gbck'><a href='#' onClick={this.BackToKolList.bind(this)} subTitleClassName="caret left" href='p'><i class="arrow left"></i> Go Back</a></label></h5> */}
                  <p>
                    {/* <a href="#" onClick={this.BackToDashboard.bind(this)}>
                      Dashboard
                    </a>{" "} */}

{sessionStorage.getItem('login_type') == 'client_demo' ?
                      <div> 
                      </div> : <>
                        <a href="#" onClick={this.BackToDashboard.bind(this)}>
                          Dashboard
                        </a>{" "}
                        {/* <b><span class="btn-label"><i class="fa fa-caret-right"></i></span></b> */}
                      </>
                    }
                    {/* <b>
                      <span class="btn-label">
                        <i class="fa fa-caret-right"></i>{" "}
                      </span>
                    </b> */}
                    <a href="#" onClick={this.BackToKolList.bind(this)}>
                      Opinion Leaders
                    </a>{" "}
                    <b>
                      <span class="btn-label">
                        <i class="fa fa-caret-right"></i>{" "}
                      </span>
                    </b>{" "}
                    Profile Details
                  </p>

                  {/* <p ><a href='#' onClick={this.BackToClientProject.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Opinion Leaders</p> */}
                </div>
                {/* <hr style={{width:'96.5%' , marginBottom:'10px',height:'.2px', marginLeft:'1%'}}></hr> */}
              </div>
            </div>

            {/* <div class="profile-backoptiopn">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12" >
                  <p ><a href='#' onClick={this.BackToDashboard.bind(this)}>Dashboard</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b><a href='#' onClick={this.BackToKolList.bind(this)} >Opinion Leaders</a> <b><span class="btn-label"><i class="fa fa-caret-right"></i>  </span></b> Profile Details</p>
            </div>
        </div>  */}

            <div class="container-fluid text-dark detailContainer">
              {/* <h3 className='pfDetails'>Profile Details <hr></hr></h3> */}
              <div className="">
                {/* <h5 className='pfDetails'><b>Profile Details</b></h5> */}
                {/* <hr style={{maxWidth:'101.5%', minWidth:'101.5%'}} ></hr>  */}

                <div className="row">
                  <div className="col col-lg-2">
                    <div className="card mb-2 crd_menu_des">
                      <div className="card-body">
                        <ul className="nav nav-pills" style={{ display: "block" }}>
                          <li className="nav-item pill-1">
                            <a
                              onClick={this.PersonalInformationFun}
                              href="#"
                              className="nav-link nav-linkss active"
                              data-bs-toggle="pill"
                            >
                              Personal Information
                              {/* {
                                                                (this.state.PersonalInformationFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Personal Information</text>
                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Personal Information</text>
                                                                    </div>
                                                                )
                                                            } */}
                            </a>
                            {/* <a onClick={this.BiographyFun} >
                                                            {
                                                                (this.state.BiographyFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Biography</text>
                                                                    </div>

                                                                ) : (
                                                                    <div><text>Biography</text>
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                      {/* <a className="nav-link nav-linkss active" data-bs-toggle="pill" href="#personalInformation">Personal Information</a> */}
                          </li>
                          <li className="nav-item pill-2">
                            <a
                              onClick={this.SummaryFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Premium Insights
                            </a>
                          </li>
                          <li className="nav-item pill-3">
                            {/* <a className="nav-link nav-linkss" data-bs-toggle="pill" href="#Biography">Biography</a> */}
                            <a
                              onClick={this.BiographyFun}
                              href="#"
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                            >
                              Biography
                              {/* {
                                                                (this.state.BiographyFlag) ? (
                                                                    <div >
                                                                        <text className="nav-link">
                                                                            Biography</text>
                                                                    </div>

                                                                ) : (
                                                                    <div><text className="nav-linkss" >Biography</text>
                                                                    </div>
                                                                )
                                                            } */}
                            </a>
                          </li>
                          <li className="nav-item pill-4">
                            {/* <a className="nav-link nav-linkss" data-bs-toggle="pill" href="#Professionaldtl">Professional</a> */}
                            <a
                              onClick={this.ProfessionalMembershipFun}
                              href="#"
                              className="nav-link nav-linkss "
                              data-bs-toggle="pill"
                            >
                              Professional Membership
                              {/* {
                                                                (this.state.ProfessionalMembershipFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Professional Membership</text>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Professional Membership</text>
                                                                    </div>
                                                                )
                                                            } */}
                            </a>
                          </li>
                          {/* <li className="nav-item pill-5">
                            <a
                              onClick={this.EducationFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Education
                            </a>
                          </li> */}
                          <li className="nav-item pill-6">
                            <a
                              onClick={this.AdvisoryBoardFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Advisory Board
                            </a>
                          </li>
                          {/* <li className="nav-item pill-7">
                            <a
                              onClick={this.AwardsAndHonoursFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Awards and Honours{" "}
                            </a>
                          </li> */}
                          {/* <li className="nav-item pill-8">
                            <a
                              onClick={this.BooksAndMonographFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Books
                            </a>
                          </li> */}
                          <li className="nav-item pill-9">
                            <a
                              onClick={this.CommiteesFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Committees
                            </a>
                          </li>
                          <li className="nav-item pill-10">
                            <a
                              onClick={this.ClinicalTrialDetailsFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Clinical Trials
                            </a>
                          </li>
                          <li className="nav-item pill-11">
                            <a
                              onClick={this.EventsDetailsFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Events
                            </a>
                          </li>
                          <li className="nav-item pill-12">
                            <a
                              onClick={this.GrantsFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Grants
                            </a>
                          </li>
                          <li className="nav-item pill-13">
                            <a
                              onClick={this.GuidelinesFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Guidelines
                            </a>
                          </li>
                          {/* <li className="nav-item pill-14">
                            <a
                              onClick={this.HospitalAffiliationsFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Hospital Affiliations
                            </a>
                          </li> */}
                          {/* <li className="nav-item pill-15">
                            <a
                              onClick={this.InteractionsFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Interactions
                            </a>
                          </li> */}
                          {/* <li className="nav-item pill-16">
                            <a
                              onClick={this.PressItemFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Press Item
                            </a>
                          </li>
                          <li className="nav-item pill-17">
                            <a
                              onClick={this.PresentationsFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Presentations
                            </a>
                          </li> */}
                          <li className="nav-item pill-18">
                            <a
                              onClick={this.PublicationDetailsFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Publications
                            </a>
                          </li>
                          <li className="nav-item pill-19">
                            <a
                              onClick={this.SunshinePaymentFun}
                              className="nav-link nav-linkss"
                              data-bs-toggle="pill"
                              href="#"
                            >
                              Sunshine Payment
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col col-lg-10">
                    <div className="card">
                      {/* <div className="card-header" style={{ textAlign: "end", background: "transparent", border: "none" }}>
                        <DropDownButton profileData={this.state} />
                      </div> */}
                      <div className="card-body">
                        <div className="row" style={{ display: 'flex' }}>
                          <div className="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                            <img
                              className="prflpge"
                              src={this.state.profile_photo}
                              alt="KOL"
                            ></img>
                          </div>
                          <div
                            className="col col-lg-8 col-md-8 col-sm-8 col-xs-8"
                            style={{ marginLeft: "-5%" }}
                          >
                            <ul1 className="hdlead">
                              <li>
                                <h6>
                                  <b>{this.state.DoctorCompleteName}</b>
                                </h6>
                              </li>
                              <li>
                                <b>Speciality: </b>
                                {this.state.PersonalInformation.specialty_id}
                              </li>
                              <li>
                                <b>Sub-speciality: </b>
                                {this.state.PersonalInformation.subSpeciality
                                  ? this.state.PersonalInformation.subSpeciality
                                  : "NA"}
                              </li>
                              {this.state.PersonalInformation.expertise !==
                                "" ? (
                                <li>
                                  <b>Expertise:</b>{" "}
                                  {this.state.PersonalInformation.expertise}
                                </li>
                              ) : (
                                <div></div>
                              )}
                              {/* <br /> */}
                              <li>
                                <div>
                                  <a className="per_btn" onClick={this.bioViewNav.bind(this)}>
                                    <button type="button" class="btn btn-rounded btn-sm per_btn_a" style={{ verticalAlign: "baseline", color: "#A51E22" }}>Bio</button></a>
                                  &nbsp;
                                  &nbsp;
                                  <a className="per_btn" onClick={this.profileInfoNav.bind(this)}>
                                    <button type="button" class="btn btn-rounded btn-sm per_btn_a" style={{ verticalAlign: "baseline", color: "#A51E22", }}>Profile</button></a>
                                </div>
                              </li>

                            </ul1>
                          </div>

                          {this.state.lock_unlock_flag == true ? (
                            <div className="col-2 mt-3">
                              <a onClick={this.toggleinfoQuoteModal.bind(this)}>
                                <div className="Buy_now_anchor">
                                  {" "}
                                  Unlock Profile
                                </div>
                              </a>
                            </div>
                          ) : (
                            // {/* <div className='col col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{marginLeft:'4%'}}>
                            //   <div className='card ratingbg'>
                            //     <div className='card-body1'>
                            //       Overall rating
                            //       <p>{this.state.PersonalInformation.aggregate_score}</p>
                            //     </div>
                            //   </div>
                            // </div>                   */}

                            <div className="col col-lg-2 col-md-2 col-sm-2 col-xs-2" style={{ display: "flex", justifyContent: "end" }}>

                              {this.state.star ? (<div style={{ marginLeft: "4%", textAlign: "center", lineHeight: "40px", fontWeight: "bold", color: `${this.state.star == "Rising star" ? "#00B050" : this.state.star == "Rock star" ? "#FFC000" : "#E40000"}` }} >
                                <FontAwesomeIcon icon={faStar} style={{ color: `${this.state.star == "Rising star" ? "#00B050" : this.state.star == "Rock star" ? "#FFC000" : "#E40000"}` }} size="2xl" /><br />
                                {this.state.star}
                              </div>) : (<div></div>)}
                              {this.state.dol ? (<div style={{ marginLeft: "4%", textAlign: "center", lineHeight: "40px", fontWeight: "bold", color: "#133861" }} >
                                <FontAwesomeIcon icon={faMobileScreenButton} style={{ "--fa-secondary-color": "#133861", "--fa-secondary-opacity": "1" }} size="2xl" /><br />
                                {this.state.dol == "true" ? "DOL" : ""}
                              </div>) : (<div></div>)}
                              {
                                this.state.sanction ? (<div style={{ marginLeft: "4%", textAlign: "center", lineHeight: "40px", fontWeight: "bold", color: "#E40000" }} >
                                  <FontAwesomeIcon icon={faGavel} size="2xl" style={{ color: "#E40000" }} /><br />
                                  {this.state.sanction == "true" ? "Sanction" : ""}
                                </div>) : (<div></div>)}
                            </div>

                            //   {/* <div className='col-2 mt-3' >
                            //         <a onClick={this.toggleinfoQuoteModal.bind(this)} >
                            //           <div  className='Buy_now_anchor'> Unlock Profile</div>
                            //         </a>
                            //  </div> */}
                          )}
                          {/* <div className='col col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{marginLeft:'4%'}}>
                      <div className='card ratingbg'>
                        <div className='card-body1'>                        
                          Overall rating
                          <p>{this.state.PersonalInformation.aggregate_score}</p>
                        </div>
                      </div>
                    </div> */}
                          {/*                     
                         <div className='col-2 mt-3' >                    
                            
                            <a onClick={this.toggleinfoQuoteModal.bind(this)} >
                              <div  className='Buy_now_anchor'> Unlock Profile</div>  
                            </a>
                    
                        </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div class="tab-content tbpnsd"> */}
                    <div class="tbpnsd">
                      {/* <a id="overall-details"></a> */}

                      {this.state.PersonalInformationFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2 active"
                          id="personalInformation"
                        >
                          <div className="card scrl_cntnt">
                            <div className="card-body">
                              <h6>
                                <b>Personal Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge">
                                  <p className="pbio">
                                    <div className="pbio_head"><i class="fa fa-address-card fa-lg" /> Basic Information</div>
                                    <li className="biogr_li">
                                      <b>Alma Mater: </b>
                                      {this.state.PersonalInformation.medical_schools}
                                    </li>
                                    <li className="biogr_li">
                                      <b>Degree: </b>
                                      {this.state.PersonalInformation.qualification}
                                    </li>
                                    <li className="biogr_li">
                                      <b>Clinical Experience: </b>
                                      {this.state.PersonalInformation.experience}
                                    </li>
                                    <li className="biogr_li">
                                      <b>License No: </b>
                                      {this.state.PersonalInformation.medical_registration_no}
                                    </li>
                                    <li className="biogr_li">
                                      <b>NPI No: </b>
                                      {this.state.PersonalInformation.NPI_no}
                                    </li>
                                    <li className="biogr_li">
                                      <b>Citizenship: </b>
                                      {this.state.Biography.citizenship}
                                    </li>
                                    <li className="biogr_li">
                                      <b>Language spoken: </b>
                                      {this.state.Biography.language_spoken}
                                    </li>
                                  </p>
                                </div>
                                <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge">
                                  {/* <div className='row'>
                        <div  style={{width:"90%", height:"30px", backgroundColor:'orange'}}>
                            <label>na</label>
                            <label>
                            <PhoneInput
                              value={this.state.PersonalInformation.doctor_contact_no}
                            />
                            </label>
                        </div>  
                         </div>   */}

                                  <p className="pbio">
                                    <div className="pbio_head"><i class="fa fa-phone fa-lg" /> Contact Information</div>
                                    <li className="biogr_li">
                                      <b>Contact No: </b>
                                      {this.state.PersonalInformation
                                        .doctor_contact_no !== "" ? (
                                        this.formatPhoneNumber(
                                          this.state.PersonalInformation
                                            .doctor_contact_no
                                        )
                                      ) : (
                                        //this.state.PersonalInformation.doctor_contact_no
                                        <div></div>
                                      )}
                                      {/* { (this.state.PersonalInformation.doctor_contact_no !=="")?
                           (
                          
                          <label style={{height:"10px"}}>
                            <PhoneInput
                              value={this.state.PersonalInformation.doctor_contact_no}
                              buttonStyle={{
                                backgroundColor: "#fff",
                                border: "1px solid #fff",
                              // borderColor: "#fff",
                                //boxShadow: "0px 0px 0px 1px #fff",
                                //visibility:false
                              display:"none",
                              //height: "1px",
                               
                              }}
                              inputStyle={{
                                //height: "24px",
                                //width: "100%",
                                //border: "0px solid #d3d3d3"
                                //outline: "none",
                                borderColor: "#fff",
                                boxShadow: "0px 0px 0px 1px #fff",
                               // position:"relative",
                               //height: "1px",
                                
                              
                              }}
                              
                              disabled
                              

                            />
                            </label>
                           ):(<div></div>)
                          } */}
                                    </li>

                                    {/* <li className='biogr_li'><b>Contact No: </b> {this.state.PersonalInformation.doctor_contact_no}</li> */}
                                    {/* <li className='biogr_li'><b>Fax  No: </b> {this.state.PersonalInformation.fax}</li> */}

                                    <li className="biogr_li">
                                      <b>Fax No: </b>
                                      {this.state.PersonalInformation.fax !==
                                        "" ? (
                                        this.formatPhoneNumber(
                                          this.state.PersonalInformation.fax
                                        )
                                      ) : (
                                        //this.state.PersonalInformation.doctor_contact_no
                                        <div></div>
                                      )}
                                    </li>

                                    <li className="biogr_li">
                                      <b>Email: </b>
                                      <a
                                        href={
                                          "mailto:" +
                                          this.state.PersonalInformation
                                            .doctor_email
                                        }
                                      >
                                        {" "}
                                        {
                                          this.state.PersonalInformation
                                            .doctor_email
                                        }
                                      </a>
                                    </li>
                                    <li className="biogr_li">
                                      <b>Secondary Email: </b>
                                      <a
                                        href={
                                          "mailto:" +
                                          this.state.PersonalInformation
                                            .doctor_email2
                                        }
                                      >
                                        {" "}
                                        {
                                          this.state.PersonalInformation
                                            .doctor_email2
                                        }
                                      </a>
                                    </li>
                                    {/* <li className='biogr_li'><b>Assistant/Secretary Contact No: </b>{this.state.PersonalInformation.assistance_contact_no}</li> */}

                                    <li className="biogr_li">
                                      <b>Assistant/Secretary Contact No: </b>

                                      {this.state.PersonalInformation
                                        .assistance_contact_no !== "" ? (
                                        this.formatPhoneNumber(
                                          this.state.PersonalInformation
                                            .assistance_contact_no
                                        )
                                      ) : (
                                        //this.state.PersonalInformation.doctor_contact_no
                                        <div></div>
                                      )}
                                    </li>

                                    <li className="biogr_li">
                                      <b>Assistant Mail: </b>
                                      <a
                                        href={
                                          "mailto:" +
                                          this.state.PersonalInformation
                                            .assistance_email
                                        }
                                      >
                                        {" "}
                                        {
                                          this.state.PersonalInformation
                                            .assistance_email
                                        }
                                      </a>
                                    </li>
                                  </p>
                                </div>
                                <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge">
                                  <p className="pbio">
                                    <div className="pbio_head"><i class="fa fa-map-marker fa-lg" /> Primary Work Address</div>
                                    <li className="biogr_li">
                                      <b>Primary Work Address: </b>
                                      {this.state.PersonalInformation.address_1}
                                      ,{" "}
                                      {this.state.PersonalInformation.address_2}
                                    </li>
                                    <li className="biogr_li">
                                      <b>City: </b>
                                      {this.state.PersonalInformation.city}
                                    </li>
                                    <li className="biogr_li">
                                      <b>State: </b>
                                      {this.state.PersonalInformation.state_id}
                                    </li>
                                    <li className="biogr_li">
                                      <b>Country: </b>
                                      {
                                        this.state.PersonalInformation
                                          .country_id
                                      }{" "}
                                      -{" "}
                                      {this.state.PersonalInformation.zip_code}
                                    </li>
                                  </p>
                                </div>
                                <div className="col col-lg-6 col-md-6 col-sm-6 col-xs-6 dtlpge">
                                  <p className="pbio">
                                    <div className="pbio_head"><i class="fa fa-link fa-lg" /> Personal Links</div>
                                    <li className="biogr_li">
                                      <b>Website URL: </b>{" "}
                                      <a
                                        href={
                                          this.state.PersonalInformation.website
                                        }
                                        target="_blank"
                                      >
                                        {" "}
                                        {this.state.PersonalInformation.website}
                                      </a>
                                    </li>
                                    <li className="biogr_li">
                                      <b>LinkedIn Profile: </b>
                                      <a
                                        href={
                                          this.state.PersonalInformation
                                            .linked_in_profile
                                        }
                                        target="_blank"
                                      >
                                        {" "}
                                        {
                                          this.state.PersonalInformation
                                            .linked_in_profile
                                        }
                                      </a>
                                    </li>
                                    <li className="biogr_li">
                                      <b>Twitter Profile: </b>
                                      <a
                                        href={
                                          this.state.PersonalInformation
                                            .twitter_handle
                                        }
                                        target="_blank"
                                      >
                                        {" "}
                                        {
                                          this.state.PersonalInformation
                                            .twitter_handle
                                        }
                                      </a>
                                    </li>
                                    <br />
                                    <hr />
                                    <li className="biogr_li" style={{ textAlign: "center" }}>
                                      <div style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                        <FontAwesomeIcon icon={faLinkedin} style={{ color: "#0077b5", fontSize: "20px" }} /> <span style={{ fontSize: "14px", textAlign: "center", justifyContent: "center", alignItems: "center" }}>&nbsp;{this.state.Biography.linkedin_followers ? this.state.Biography.linkedin_followers : "-"}</span>&nbsp;&nbsp;
                                        <FontAwesomeIcon icon={faTwitter} style={{ color: "#1da1f2", fontSize: "20px" }} /> <span style={{ fontSize: "14px", textAlign: "center", justifyContent: "center", alignItems: "center" }}>&nbsp; {this.state.Biography.twitter_followers ? this.state.Biography.twitter_followers : "-"}</span>&nbsp;&nbsp;
                                        <FontAwesomeIcon icon={faYoutube} style={{ color: "#ff0000", fontSize: "20px" }} /> <span style={{ fontSize: "14px", textAlign: "center", justifyContent: "center", alignItems: "center" }}>&nbsp;{this.state.Biography.You_tube_presence ? this.state.Biography.You_tube_presence : "-"}</span>
                                      </div>
                                    </li>
                                  </p>
                                </div>

                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge">
                                  <p className="pbio_table">
                                    <div className="pbio_head"><i class="fa fa-briefcase fa-lg" /> Work Details</div>
                                    <br />
                                    <div className="table-responsive mb-1">
                                      {/* <table className="table table-striped mb-2"> */}
                                      <div>
                                        <BootstrapTable
                                          keyField="id"
                                          noDataIndication="Table is Empty"
                                          data={this.state.WorkDetails}
                                          columns={
                                            this.state.columnsWorkDetails
                                          }
                                          filter={filterFactory()}
                                          // striped
                                          hover
                                          condensed
                                          rowStyle={{
                                            backgroundColor: "#fff",
                                          }}
                                        />
                                      </div>
                                      {/* </table> */}
                                    </div>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.SummaryFlag == true ? (
                        <div class="tab-pane container-xxxl mt-2" id="Summary">
                          <ul className="nav nav-pills pl-4">
                            <li className="nav-item pill-20">
                              <a
                                className="nav-link nav-linkss active"
                                onClick={this.HomePiechartFun}
                                data-bs-toggle="pill"
                                href="#home_sum"
                              >
                                Home
                              </a>
                            </li>
                            <li className="nav-item pill-20">
                              <a
                                className="nav-link nav-linkss"
                                onClick={this.activityTabShow}
                                data-bs-toggle="pill"
                                href="#activity"
                              >
                                Activity Trends
                              </a>
                            </li>
                            <li className="nav-item pill-21">
                              <a
                                className="nav-link nav-linkss"
                                data-bs-toggle="pill"
                                onClick={this.CompitiorAlignmentTab}
                                href="#compitiorTab"
                              >
                                Competitor Alignment
                              </a>
                            </li>
                            <li className="nav-item pill-22">
                              <a
                                className="nav-link nav-linkss"
                                data-bs-toggle="pill"
                                onClick={this.PublicationPiechartFun}
                                href="#colabrationTab"
                              >
                                Collaboration Network
                              </a>
                            </li>
                          </ul>

                          <div class="tbpnsd1">
                            {this.state.home_piechart_flag && (
                              <div
                                class="tab-pane container-xxl mt-2 active"
                                id="compitiorTab"
                              >
                                <div className="card scrl_cntnts">
                                  <div className="card-body">
                                    <br />
                                    <div class="container row" style={{ justifyContent: "center" }}>
                                      <BootstrapTable
                                        noDataIndication="Table is Empty"
                                        keyField='current_place_of_work'
                                        data={this.state.premiumInsightDetails} columns={this.state.premiumInsightColumns}
                                        rowStyle={{ backgroundColor: '#fff', borderRight: 'solid', borderBottom: 'solid', borderBottomColor: '#EDE9E8', borderRightColor: '#fff', fontSize: '12px', borderLeft: 'solid', borderLeftColor: '#fff' }}
                                        hover
                                        condensed
                                        borderless
                                      />
                                      {/* <div className="row col-sm-4" style={{ display: "block" }}>
                                        <h4>Is Known For</h4>
                                        <br />
                                        {this.state.focusAreas.Clinical ?
                                          (<div style={{ textAlign: "center", border: "1px solid #B97303B2", paddingTop: "10px", height: "75px", width: "150px", borderRadius: "10px", backgroundColor: "#B9730333", color: "black", margin: "10px", position: "relative" }}>
                                            <span class="position-absolute top-0 start-100 translate-middle badge p-2" style={{ backgroundColor: "#B97303" }}>
                                              {this.state.focusAreas.Clinical}
                                              <span class="visually-hidden">unread messages</span>
                                            </span>
                                            <FontAwesomeIcon icon={faPersonDotsFromLine} /><br />
                                            Clinical Trials
                                          </div>) : <div></div>}
                                        {this.state.focusAreas.Guideline ?
                                          (<div style={{ textAlign: "center", border: "1px solid #609706B2", paddingTop: "10px", height: "75px", width: "150px", borderRadius: "10px", backgroundColor: "#60970633", color: "black", margin: "10px", position: "relative" }}>
                                            <span class="position-absolute top-0 start-100 translate-middle badge p-2" style={{ backgroundColor: "#609706" }}>
                                              {this.state.focusAreas.Guideline}
                                              <span class="visually-hidden">unread messages</span>
                                            </span>
                                            <FontAwesomeIcon icon={faClipboardList} /><br />
                                            Guidelines
                                          </div>) : <div></div>}
                                        {this.state.focusAreas.Publication ? (<div style={{ textAlign: "center", border: "1px solid #008C73B2", paddingTop: "10px", height: "75px", width: "150px", borderRadius: "10px", backgroundColor: "#008C7333", color: "black", margin: "10px", position: "relative" }}>
                                          <span class="position-absolute top-0 start-100 translate-middle badge p-2" style={{ backgroundColor: "#008C73" }}>
                                            {this.state.focusAreas.Publication}
                                            <span class="visually-hidden">unread messages</span>
                                          </span>
                                          <FontAwesomeIcon icon={faNewspaper} /><br />
                                          Publications
                                        </div>) : <div></div>}
                                        {this.state.focusAreas.Advisory ? (<div style={{ textAlign: "center", border: "1px solid #89008CB2", paddingTop: "10px", height: "75px", width: "150px", borderRadius: "10px", backgroundColor: "#89008C33", color: "black", margin: "10px", position: "relative" }}>
                                          <span class="position-absolute top-0 start-100 translate-middle badge p-2" style={{ backgroundColor: "#89008C" }}>
                                            {this.state.focusAreas.Advisory}
                                            <span class="visually-hidden">unread messages</span>
                                          </span>
                                          <FontAwesomeIcon icon={faPeopleGroup} /><br />
                                          Advisory Board
                                        </div>) : <div></div>}
                                        {this.state.focusAreas.Event ? (<div style={{ textAlign: "center", border: "1px solid #AA0418B2", paddingTop: "10px", height: "75px", width: "150px", borderRadius: "10px", backgroundColor: "#AA041833", color: "black", margin: "10px", position: "relative" }}>
                                          <span class="position-absolute top-0 start-100 translate-middle badge p-2" style={{ backgroundColor: "#AA0418" }}>
                                            {this.state.focusAreas.Event}
                                            <span class="visually-hidden">unread messages</span>
                                          </span>
                                          <FontAwesomeIcon icon={faPersonChalkboard} /><br />
                                          Events/Conferences
                                        </div>) : <div></div>}
                                      </div>
                                      <div class="vr" />
                                      <div className="row col-sm-4 ">
                                        <h4>Focus Procedure(s):</h4>
                                        <ol class="list-group list-group-flush list-group-numbered">
                                          {this.state.focusProcedures.map(proc => {
                                            return <li class="list-group-item">{proc.procedure_type}</li>
                                          })}
                                        </ol>
                                      </div>
                                      <div class="vr" />
                                      <div className="row col-sm-4 offset-sm-1" style={{ marginLeft: "10px" }}>
                                        <h4>Focus Condition(s):</h4>
                                        <ol class="list-group list-group-flush list-group-numbered">
                                          {this.state.focusConditions.map(cond => {
                                            return <li class="list-group-item">{cond.maping_condition}</li>
                                          })}
                                        </ol>
                                      </div> */}
                                    </div>
                                    {/* <br /> <br /> */}
                                  </div>
                                </div>
                              </div>
                            )}
                            {this.state.activityTab == true ? (
                              this.state.clientTrends ? (
                                <div
                                  class="tab-pane container-xxl mt-2 active"
                                  id="home_sum"
                                >
                                  <div className="card scrl_cntnts">
                                    <div className="card-body">
                                      <div className="row center">
                                        {/* <div
                                        className="col-9"
                                        style={{ marginBottom: "80px" }}
                                      >
                                        <Chart
                                          chartType={"ColumnChart"}
                                          loader={<div>Loading Chart</div>}
                                          data={[
                                            [
                                              "",
                                              "Lifetime",
                                              { role: "annotation" },
                                              this.state.year.latest_year.toString(),
                                              { role: "annotation" },
                                              this.state.year.mid_year.toString(),
                                              { role: "annotation" },
                                              this.state.year.last_year.toString(),
                                              { role: "annotation" },
                                            ],
                                            [
                                              "Overall",
                                              this.state.Summaryoverall
                                                .Lifetime,
                                              this.state.Summaryoverall
                                                .Lifetime,
                                              this.state.Summaryoverall
                                                .latest_year,
                                              this.state.Summaryoverall
                                                .latest_year,
                                              this.state.Summaryoverall
                                                .mid_year,
                                              this.state.Summaryoverall
                                                .mid_year,
                                              this.state.Summaryoverall
                                                .last_year,
                                              this.state.Summaryoverall
                                                .last_year,
                                            ],
                                            [
                                              "Trial",
                                              this.state
                                                .Summaryclinicaltrialdetails
                                                .Lifetime,
                                              this.state
                                                .Summaryclinicaltrialdetails
                                                .Lifetime,
                                              this.state
                                                .Summaryclinicaltrialdetails
                                                .latest_year,
                                              this.state
                                                .Summaryclinicaltrialdetails
                                                .latest_year,
                                              this.state
                                                .Summaryclinicaltrialdetails
                                                .mid_year,
                                              this.state
                                                .Summaryclinicaltrialdetails
                                                .mid_year,
                                              this.state
                                                .Summaryclinicaltrialdetails
                                                .last_year,
                                              this.state
                                                .Summaryclinicaltrialdetails
                                                .last_year,
                                            ],
                                            [
                                              "Guideline",
                                              this.state.Summaryguidlines
                                                .Lifetime,
                                              this.state.Summaryguidlines
                                                .Lifetime,
                                              this.state.Summaryguidlines
                                                .latest_year,
                                              this.state.Summaryguidlines
                                                .latest_year,
                                              this.state.Summaryguidlines
                                                .mid_year,
                                              this.state.Summaryguidlines
                                                .mid_year,
                                              this.state.Summaryguidlines
                                                .last_year,
                                              this.state.Summaryguidlines
                                                .last_year,
                                            ],
                                            [
                                              "Event",
                                              this.state.Summaryeventdetails
                                                .Lifetime,
                                              this.state.Summaryeventdetails
                                                .Lifetime,
                                              this.state.Summaryeventdetails
                                                .latest_year,
                                              this.state.Summaryeventdetails
                                                .latest_year,
                                              this.state.Summaryeventdetails
                                                .mid_year,
                                              this.state.Summaryeventdetails
                                                .mid_year,
                                              this.state.Summaryeventdetails
                                                .last_year,
                                              this.state.Summaryeventdetails
                                                .last_year,
                                            ],
                                            [
                                              "Publication",
                                              this.state
                                                .Summarypublicationdetails
                                                .Lifetime,
                                              this.state
                                                .Summarypublicationdetails
                                                .Lifetime,
                                              this.state
                                                .Summarypublicationdetails
                                                .latest_year,
                                              this.state
                                                .Summarypublicationdetails
                                                .latest_year,
                                              this.state
                                                .Summarypublicationdetails
                                                .mid_year,
                                              this.state
                                                .Summarypublicationdetails
                                                .mid_year,
                                              this.state
                                                .Summarypublicationdetails
                                                .last_year,
                                              this.state
                                                .Summarypublicationdetails
                                                .last_year,
                                            ],
                                            [
                                              "Advisory",
                                              this.state.Summaryadvisoryboard
                                                .Lifetime,
                                              this.state.Summaryadvisoryboard
                                                .Lifetime,
                                              this.state.Summaryadvisoryboard
                                                .latest_year,
                                              this.state.Summaryadvisoryboard
                                                .latest_year,
                                              this.state.Summaryadvisoryboard
                                                .mid_year,
                                              this.state.Summaryadvisoryboard
                                                .mid_year,
                                              this.state.Summaryadvisoryboard
                                                .last_year,
                                              this.state.Summaryadvisoryboard
                                                .last_year,
                                            ],
                                          ]}
                                          options={EngagementSumChartOptions}
                                          width={"100%"}
                                          height={"350px"}
                                          rootProps={{ "data-testid": "1" }}
                                        />
                                      </div>
                                      <div
                                        className="col-9"
                                        style={{ marginBottom: "80px" }}
                                      >
                                        <Chart
                                          chartType={"ColumnChart"}
                                          data={[
                                            [
                                              "",
                                              "Lifetime",
                                              { role: "annotation" },
                                              this.state.year.latest_year.toString(),
                                              { role: "annotation" },
                                              this.state.year.mid_year.toString(),
                                              { role: "annotation" },
                                              this.state.year.last_year.toString(),
                                              { role: "annotation" },
                                            ],

                                            [
                                              "Clinical Trials",
                                              this.state
                                                .RecentTrendclinicaltrialdetails
                                                .Lifetime,
                                              this.state
                                                .RecentTrendclinicaltrialdetails
                                                .Lifetime,
                                              this.state
                                                .RecentTrendclinicaltrialdetails
                                                .latest_year,
                                              this.state
                                                .RecentTrendclinicaltrialdetails
                                                .latest_year,
                                              this.state
                                                .RecentTrendclinicaltrialdetails
                                                .mid_year,
                                              this.state
                                                .RecentTrendclinicaltrialdetails
                                                .mid_year,
                                              this.state
                                                .RecentTrendclinicaltrialdetails
                                                .last_year,
                                              this.state
                                                .RecentTrendclinicaltrialdetails
                                                .last_year,
                                            ],
                                            [
                                              "Guidelines",
                                              this.state.RecentTrendguidlines
                                                .Lifetime,
                                              this.state.RecentTrendguidlines
                                                .Lifetime,
                                              this.state.RecentTrendguidlines
                                                .latest_year,
                                              this.state.RecentTrendguidlines
                                                .latest_year,
                                              this.state.RecentTrendguidlines
                                                .mid_year,
                                              this.state.RecentTrendguidlines
                                                .mid_year,
                                              this.state.RecentTrendguidlines
                                                .last_year,
                                              this.state.RecentTrendguidlines
                                                .last_year,
                                            ],
                                            [
                                              "Events/Conferences",
                                              this.state.RecentTrendeventdetails
                                                .Lifetime,
                                              this.state.RecentTrendeventdetails
                                                .Lifetime,
                                              this.state.RecentTrendeventdetails
                                                .latest_year,
                                              this.state.RecentTrendeventdetails
                                                .latest_year,
                                              this.state.RecentTrendeventdetails
                                                .mid_year,
                                              this.state.RecentTrendeventdetails
                                                .mid_year,
                                              this.state.RecentTrendeventdetails
                                                .last_year,
                                              this.state.RecentTrendeventdetails
                                                .last_year,
                                            ],
                                            [
                                              "Publication",
                                              this.state
                                                .RecentTrendpublicationdetails
                                                .Lifetime,
                                              this.state
                                                .RecentTrendpublicationdetails
                                                .Lifetime,
                                              this.state
                                                .RecentTrendpublicationdetails
                                                .latest_year,
                                              this.state
                                                .RecentTrendpublicationdetails
                                                .latest_year,
                                              this.state
                                                .RecentTrendpublicationdetails
                                                .mid_year,
                                              this.state
                                                .RecentTrendpublicationdetails
                                                .mid_year,
                                              this.state
                                                .RecentTrendpublicationdetails
                                                .last_year,
                                              this.state
                                                .RecentTrendpublicationdetails
                                                .last_year,
                                            ],
                                            [
                                              "Advisory",
                                              this.state
                                                .RecentTrendadvisoryboard
                                                .Lifetime,
                                              this.state
                                                .RecentTrendadvisoryboard
                                                .Lifetime,
                                              this.state
                                                .RecentTrendadvisoryboard
                                                .latest_year,
                                              this.state
                                                .RecentTrendadvisoryboard
                                                .latest_year,
                                              this.state
                                                .RecentTrendadvisoryboard
                                                .mid_year,
                                              this.state
                                                .RecentTrendadvisoryboard
                                                .mid_year,
                                              this.state
                                                .RecentTrendadvisoryboard
                                                .last_year,
                                              this.state
                                                .RecentTrendadvisoryboard
                                                .last_year,
                                            ],
                                          ]}
                                          options={RecentTrendChartOptions}
                                          width={"100%"}
                                          height={"350px"}
                                          rootProps={{ "data-testid": "1" }}
                                        />
                                      </div>
                                      <div
                                        className="col-9"
                                        style={{ marginBottom: "80px" }}
                                      >
                                        <Chart
                                          //chartType="ColumnChart"
                                          chartType={"ColumnChart"}
                                          // loader={<div>Loading Data...</div>}

                                          data={[
                                            [
                                              "",
                                              "Global",
                                              { role: "annotation" },
                                              "Regional",
                                              { role: "annotation" },
                                              "National",
                                              { role: "annotation" },
                                              "Local",
                                              { role: "annotation" },
                                            ],
                                            [
                                              this.state.year.Lifetime.toString(),
                                              this.state.EventsGeographicGlobal
                                                .Lifetime,
                                              this.state.EventsGeographicGlobal
                                                .Lifetime + "%",
                                              this.state
                                                .EventsGeographicRegional
                                                .Lifetime,
                                              this.state
                                                .EventsGeographicRegional
                                                .Lifetime + "%",
                                              this.state
                                                .EventsGeographicNational
                                                .Lifetime,
                                              this.state
                                                .EventsGeographicNational
                                                .Lifetime + "%",
                                              this.state.EventsGeographicLocal
                                                .Lifetime,
                                              this.state.EventsGeographicLocal
                                                .Lifetime + "%",
                                            ],
                                            [
                                              this.state.year.latest_year.toString(),
                                              this.state.EventsGeographicGlobal
                                                .latest_year,
                                              this.state.EventsGeographicGlobal
                                                .latest_year + "%",
                                              this.state
                                                .EventsGeographicRegional
                                                .latest_year,
                                              this.state
                                                .EventsGeographicRegional
                                                .latest_year + "%",
                                              this.state
                                                .EventsGeographicNational
                                                .latest_year,
                                              this.state
                                                .EventsGeographicNational
                                                .latest_year + "%",
                                              this.state.EventsGeographicLocal
                                                .latest_year,
                                              this.state.EventsGeographicLocal
                                                .latest_year + "%",
                                            ],
                                            [
                                              this.state.year.mid_year.toString(),
                                              this.state.EventsGeographicGlobal
                                                .mid_year,
                                              this.state.EventsGeographicGlobal
                                                .mid_year + "%",
                                              this.state
                                                .EventsGeographicRegional
                                                .mid_year,
                                              this.state
                                                .EventsGeographicRegional
                                                .mid_year + "%",
                                              this.state
                                                .EventsGeographicNational
                                                .mid_year,
                                              this.state
                                                .EventsGeographicNational
                                                .mid_year + "%",
                                              this.state.EventsGeographicLocal
                                                .mid_year,
                                              this.state.EventsGeographicLocal
                                                .mid_year + "%",
                                            ],
                                            [
                                              this.state.year.last_year.toString(),
                                              this.state.EventsGeographicGlobal
                                                .last_year,
                                              this.state.EventsGeographicGlobal
                                                .last_year + "%",
                                              this.state
                                                .EventsGeographicRegional
                                                .last_year,
                                              this.state
                                                .EventsGeographicRegional
                                                .last_year + "%",
                                              this.state
                                                .EventsGeographicNational
                                                .last_year,
                                              this.state
                                                .EventsGeographicNational
                                                .last_year + "%",
                                              this.state.EventsGeographicLocal
                                                .last_year,
                                              this.state.EventsGeographicLocal
                                                .last_year + "%",
                                            ],
                                          ]}
                                          options={
                                            EventConfrenceGeographicChartOptions
                                          }
                                          //view={view}

                                          width={"100%"}
                                          height={"350px"}
                                          rootProps={{ "data-testid": "1" }}
                                        />
                                      </div>

                                      <div
                                        className="col-9"
                                        style={{ marginBottom: "80px" }}
                                      >
                                        <Chart
                                          //chartType="ColumnChart"
                                          chartType={"ColumnChart"}
                                          // loader={<div>Loading Data...</div>}

                                          data={[
                                            [
                                              "",
                                              "Event Chair",
                                              { role: "annotation" },
                                              "Moderator/Chair",
                                              { role: "annotation" },
                                              "Regular",
                                              { role: "annotation" },
                                              "Keynote Speaker",
                                              { role: "annotation" },
                                            ],
                                            [
                                              this.state.year.Lifetime.toString(),
                                              this.state.EventsRoleChairperson
                                                .Lifetime,
                                              this.state.EventsRoleChairperson
                                                .Lifetime + "%",
                                              this.state.EventsRoleModerator
                                                .Lifetime,
                                              this.state.EventsRoleModerator
                                                .Lifetime + "%",
                                              this.state.EventsRoleRegular
                                                .Lifetime,
                                              this.state.EventsRoleRegular
                                                .Lifetime + "%",
                                              this.state.EventsRoleKeynote
                                                .Lifetime,
                                              this.state.EventsRoleKeynote
                                                .Lifetime + "%",
                                            ],
                                            [
                                              this.state.year.latest_year.toString(),
                                              this.state.EventsRoleChairperson
                                                .latest_year,
                                              this.state.EventsRoleChairperson
                                                .latest_year + "%",
                                              this.state.EventsRoleModerator
                                                .latest_year,
                                              this.state.EventsRoleModerator
                                                .latest_year + "%",
                                              this.state.EventsRoleRegular
                                                .latest_year,
                                              this.state.EventsRoleRegular
                                                .latest_year + "%",
                                              this.state.EventsRoleKeynote
                                                .latest_year,
                                              this.state.EventsRoleKeynote
                                                .latest_year + "%",
                                            ],
                                            [
                                              this.state.year.mid_year.toString(),
                                              this.state.EventsRoleChairperson
                                                .mid_year,
                                              this.state.EventsRoleChairperson
                                                .mid_year + "%",
                                              this.state.EventsRoleModerator
                                                .mid_year,
                                              this.state.EventsRoleModerator
                                                .mid_year + "%",
                                              this.state.EventsRoleRegular
                                                .mid_year,
                                              this.state.EventsRoleRegular
                                                .mid_year + "%",
                                              this.state.EventsRoleKeynote
                                                .mid_year,
                                              this.state.EventsRoleKeynote
                                                .mid_year + "%",
                                            ],
                                            [
                                              this.state.year.last_year.toString(),
                                              this.state.EventsRoleChairperson
                                                .last_year,
                                              this.state.EventsRoleChairperson
                                                .last_year + "%",
                                              this.state.EventsRoleModerator
                                                .last_year,
                                              this.state.EventsRoleModerator
                                                .last_year + "%",
                                              this.state.EventsRoleRegular
                                                .last_year,
                                              this.state.EventsRoleRegular
                                                .last_year + "%",
                                              this.state.EventsRoleKeynote
                                                .last_year,
                                              this.state.EventsRoleKeynote
                                                .last_year + "%",
                                            ],
                                          ]}
                                          options={
                                            EventConfrenceRoleChartOptions
                                          }
                                          //view={view}

                                          width={"100%"}
                                          height={"350px"}
                                          rootProps={{ "data-testid": "1" }}
                                        />
                                      </div> */}

                                        <div
                                          className="col-12"
                                          style={{ marginBottom: "40px" }}
                                        >
                                          <br />
                                          <h4 className="trend-heading">
                                            Publications
                                          </h4>
                                          <Chart
                                            //chartType="ColumnChart"
                                            chartType={"BarChart"}
                                            loader={<div>Loading Data...</div>}
                                            data={[
                                              // ["Year", "Value"], ["2021", 2], ["2022", "3"], ["2023", "4"] Sample Data
                                              ["Year", "Value"], ...this.state.clientTrends.publications.map(y => [y.year, parseInt(y.value)], ["Lifetime", parseInt(this.state.clientTrends.publications.map(y => y.value).reduce((a, b) => a + b))],)
                                            ]}
                                            options={commonChartOptions}
                                            //view={view}

                                            width={"100%"}
                                            height={"200px"}
                                            rootProps={{ "data-testid": "1" }}
                                          />
                                        </div>
                                        <div
                                          className="col-6"
                                          style={{ marginBottom: "40px" }}
                                        >
                                          <h4 className="trend-heading">
                                            Clinical Trials:
                                          </h4>
                                          <Chart
                                            //chartType="ColumnChart"
                                            chartType={"BarChart"}
                                            loader={<div>Loading Data...</div>}
                                            data={[
                                              ["Year", "Value"], ...this.state.clientTrends.clinicalTrails.map(y => [y.year, parseInt(y.value)], ["Lifetime", parseInt(this.state.clientTrends.clinicalTrails.map(y => y.value).reduce((a, b) => a + b))])
                                            ]}
                                            options={commonChartOptions}
                                            //view={view}

                                            width={"100%"}
                                            height={"200px"}
                                            rootProps={{ "data-testid": "1" }}
                                          />
                                        </div>


                                        <div
                                          className="col-6"
                                          style={{ marginBottom: "40px" }}
                                        >
                                          <h4 className="trend-heading">Guidelines:</h4>
                                          <Chart
                                            chartType={"BarChart"}
                                            loader={<div>Loading Data...</div>}
                                            data={[
                                              ["Year", "Value"], ...this.state.clientTrends.guideLines.map(y => [y.year, parseInt(y.value)], ["Lifetime", parseInt(this.state.clientTrends.guideLines.map(y => y.value).reduce((a, b) => a + b))])
                                            ]}
                                            options={commonChartOptions}
                                            width={"100%"}
                                            height={"200px"}
                                            rootProps={{ "data-testid": "1" }}
                                          />
                                        </div>

                                        <div className="col-6">
                                          <h4 className="trend-heading">Events/Conferences:</h4>
                                          <Chart
                                            chartType={"BarChart"}
                                            loader={<div>Loading Data...</div>}
                                            data={[
                                              ["Year", "Value"], ...this.state.clientTrends.eventsConference.map(y => [y.year, parseInt(y.value)], ["Lifetime", parseInt(this.state.clientTrends.eventsConference.map(y => y.value).reduce((a, b) => a + b))])
                                            ]}
                                            options={commonChartOptions}
                                            width={"100%"}
                                            height={"200px"}
                                            rootProps={{ "data-testid": "1" }}
                                          />
                                        </div>

                                        <div className="col-6">
                                          <h4 className="trend-heading">Advisory Board:</h4>
                                          <Chart
                                            //chartType="ColumnChart"
                                            chartType={"BarChart"}
                                            loader={<div>Loading Data...</div>}
                                            data={[
                                              ["Year", "Value"], ...this.state.clientTrends.advisoryBoard.map(y => [y.year, parseInt(y.value)], ["Lifetime", parseInt(this.state.clientTrends.advisoryBoard.map(y => y.value).reduce((a, b) => a + b))])
                                            ]}
                                            options={commonChartOptions}
                                            width={"100%"}
                                            height={"200px"}
                                            rootProps={{ "data-testid": "1" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <br />  <br />
                                  </div>
                                </div>
                              ) : (
                                <h4>Loading Trends...</h4>
                              )
                            ) : (
                              <div></div>
                            )}
                            {this.state.showCompitiorTab && (
                              <div
                                class="tab-pane container-xxl mt-2 active"
                                id="compitiorTab"
                              >
                                <div className="card scrl_cntnts">
                                  <div className="card-body">
                                    <div className="row center">
                                      <h4>Coming Soon...</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {this.state.colabarationTab && (
                              <div
                                class="tab-pane container-xxl mt-2 active"
                                id="compitiorTab"
                              >
                                <div className="card scrl_cntnts">
                                  <div className="card-body">
                                    <div className="row center">
                                      <h4>Coming Soon...</h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {/* <a id="advisory-board"></a> */}
                      {this.state.BiographyFlag == true ? (
                        <div class="tab-pane container-xxl mt-2" id="Biography">
                          <div className="card mb-3 scrl_cntnt">
                            <div className="card-body">
                              <h6>
                                <b>Biography Details</b>
                              </h6>
                              <hr></hr>
                              {/* <div className="row">
                                <div className="col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge">
                                  <p>
                                    <b>Citizenship:</b>{" "}
                                    {this.state.Biography.citizenship}
                                  </p>
                                </div>
                                <div className="col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge">
                                  <p>
                                    <b>Language Spoken:</b>{" "}
                                    {this.state.Biography.language_spoken}
                                  </p>
                                </div>
                                <div className="col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge">
                                  <p>
                                    <b>Linkedin Followers:</b>{" "}
                                    {this.state.Biography.linkedin_followers}
                                  </p>
                                </div>
                                <div className="col col-lg-3 col col-md-3 col col-sm-3 col col-xs-3 dtlpge">
                                  <p>
                                    <b>Twitter Followers:</b>{" "}
                                    {this.state.Biography.twitter_followers}
                                  </p>
                                </div>
                              </div> */}
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge">
                                  <p>
                                    <b>Areas of Interest:</b>
                                    {this.state.Biography.areas_of_interest}
                                  </p>
                                </div>
                                {/* <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge'>
                          <p><b>Expertise:</b> {this.state.PersonalInformation.expertise}</p>
                        </div> */}
                              </div>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 dtlpge">
                                  <p className="pbio_table">
                                    <b>Biography:</b>
                                    <view
                                      style={{ whiteSpace: "break-spaces" }}
                                    >
                                      {this.state.Biography.biography}
                                    </view>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {/* Professional membership */}
                      {this.state.ProfessionalMembershipFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Professionaldtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Professional Membership Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          // responsive
                                          // scrollX
                                          // wrapperClasses="table-responsive"
                                          // wrapperClasses ="tablebootstrap"
                                          // style={{width:'200%'}}
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={
                                            this.state.ProfessionalMemberships
                                          }
                                          columns={
                                            this.state
                                              .columnsProfessionalMemberships
                                          }
                                          filter={filterFactory()}
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {this.state.EducationFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Educationdtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Education Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.Education}
                                          columns={this.state.columnsEducation}
                                          filter={filterFactory()}
                                          // striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {this.state.AdvisoryBoardFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Advisorydtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Advisory Board Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.AdvisoryBoard}
                                          columns={
                                            this.state.columnsAdvisoryBord
                                          }
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {this.state.AwardsAndHonoursFlag == true ? (
                        <div class="tab-pane container-xxl mt-2" id="Awardsdtl">
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Awards and Honours Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.AwardsHonours}
                                          columns={
                                            this.state.columnsAwardsHonours
                                          }
                                          // striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.BooksAndMonographFlag == true ? (
                        <div class="tab-pane container-xxl mt-2" id="Booksdtl">
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Books and Monographs</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.BooksMonographs}
                                          columns={
                                            this.state.columnsBooksMonographs
                                          }
                                          //  striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.CommiteesFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Committeedtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Committee Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.Commitees}
                                          columns={this.state.columnsCommitees}
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.ClinicalTrialDetailsFlag == true ? (
                        <div class="tab-pane container-xxl mt-2" id="ctdtl">
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Clinical Trial Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.ClinicalTrialDetails}
                                          columns={
                                            this.state
                                              .columnsClinicalTrialDetails
                                          }
                                          // striped
                                          hover
                                          rowStyle={{ backgroundColor: "#fff" }}
                                          condensed
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.EventsDetailsFlag == true ? (
                        <div class="tab-pane container-xxl mt-2" id="Eventdtl">
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Event Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.EventsDetails}
                                          columns={
                                            this.state.columnsEventsDetails
                                          }
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.GrantsFlag == true ? (
                        <div class="tab-pane container-xxl mt-2" id="Grantsdtl">
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Grants Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.Grants}
                                          columns={this.state.columnsGrants}
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.GuidelinesFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Guidelinesdtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Guidelines Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.Guidelines}
                                          columns={this.state.columnsGuidelines}
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.HospitalAffiliationsFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Hospitaldtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Hospital Affiliation Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.HospitalAffiliations}
                                          columns={
                                            this.state
                                              .columnsHospitalAffiliations
                                          }
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.InteractionsFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Interactionsdtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Interactions Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.Interactions}
                                          columns={
                                            this.state.columnsInteractions
                                          }
                                          //  striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.PressItemFlag == true ? (
                        <div class="tab-pane container-xxl mt-2" id="Pressdtl">
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Press Item Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.PressItem}
                                          columns={this.state.columnsPressItem}
                                          //striped

                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.PresentationsFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Presentationsdtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Presentation Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.Presentation}
                                          columns={
                                            this.state.columnsPresentation
                                          }
                                          // striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.PublicationDetailsFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Publicationsdtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Publication Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.PublicationDetails}
                                          columns={
                                            this.state.columnsPublicationDetails
                                          }
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {this.state.SunshinePaymentFlag == true ? (
                        <div
                          class="tab-pane container-xxl mt-2"
                          id="Sunshinedtl"
                        >
                          <div className="card mb-3 scrl_cntntss">
                            <div className="card-body">
                              <h6>
                                <b>Sunshine Payment Details</b>
                              </h6>
                              <hr></hr>
                              <div className="row">
                                <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="table-responsive table_srls">
                                    <table className="table table-striped mb-2">
                                      <div className="bg-light">
                                        <BootstrapTable
                                          noDataIndication="Table is Empty"
                                          keyField="id"
                                          data={this.state.SunshinePayment}
                                          columns={
                                            this.state.columnsSunshinePayment
                                          }
                                          //striped
                                          hover
                                          condensed
                                          rowStyle={{ backgroundColor: "#fff" }}
                                        />
                                      </div>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {/* 
                <div class="tab-pane container-xxl mt-2" id="">
                  <div className='card mb-3 scrl_cntntss'>
                    <div className='card-body'>
                      <h6><b></b></h6><hr></hr>
                      <div className='row'>
                        <div className='col col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                          <div className='table-responsive table_srls'>
                            <table className='table table-striped mb-2'>
                              <thead className='bg-light'>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody className='table_srl'>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                      {/* infoQuoteModal */}

                      <Modal isOpen={this.state.infoQuoteModal} centered>
                        {/* toggle={this.toggleinfoQuoteModal.bind(this)} */}
                        <ModalHeader
                          toggle={this.toggleinfoQuoteModal.bind(this)}
                          cssModule={{
                            "modal-title": "w-100 text-center",
                            "border-bottom": "0px",
                          }}
                          style={{ borderBottom: "0px" }}
                        >
                          <h3
                            style={{
                              justifyContent: "center",
                              marginTop: "20px",
                            }}
                            id="login-tit"
                          >
                            Get a quote
                          </h3>
                        </ModalHeader>
                        <ModalBody style={{ padding: "0px 60px 0px 60px" }}>
                          <Quoteinfoform></Quoteinfoform>
                        </ModalBody>
                        {/* <ModalFooter style={{ borderTop: '0px' , paddingBottom:'40px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '90px' }}
                                                onClick={this.toggleinfoQuoteModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '30%', height: '40px', width: '90px'}}
                                                onClick={this.SaveQuoteinfo.bind(this)}>
                                                Next
                                            </button>

                                        </ModalFooter> */}
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <footer class="footer_not">

                                  <div class="cetas-footer">
                                      <div class="container">
                                          <p>All rights reserved. www.cetashealthcare.com</p>
                                      </div>
                                  </div>
            </footer>  */}
      </div >
    );
  }
}

export default ClientKolProfile;